import styles from './index.module.css'
import CommonMainLayout from '@/components/CommonMainLayout';
import subscription from '@/assets/images/subscription.png';
import { useState } from 'react';
import { Modal, Button } from 'antd'
import { paypalSubscriptionOp, stripeSubscriptionOp } from '@/api/Subscription'
import InvoicesDownload from '@/components/InvoicesDownload';
import SuccessIcon from '@/assets/images/SuccessIcon.png'
const SubscriptionMain = ({ PayData, TaxInfo, initTaxInfo, UserInfo, initPageData }) => {
    const [InvoicesStatus, setInvoicesStatus] = useState(false) //发票
    const [SuccessDiaLog, setSuccessDiaLog] = useState(false) //续约取消状态
    const [notActionConfirm, setnotActionConfirm] = useState(false) //续约弹窗按钮状态

    const CloseSuccessDiaLog = () => {
        setSuccessDiaLog(false)
    }

    const closeInvoicesStatus = () => {
        setInvoicesStatus(false)
    }

    const OpenInvoicesStatus = () => {
        setInvoicesStatus(true)
    }

    // status 1 正常 2取消订阅

    const paypalSubscriptionOpFun = () => {
        if (notActionConfirm) {
            return false
        }
        setnotActionConfirm(true)
        if (PayData.payTypeName != 'Paypal') {
            if (PayData.orders[0].subscriptionId) {
                stripeSubscriptionOp({ subscriptionId: PayData.orders[0].subscriptionId }).then(res => {
                    setTimeout(() => {
                        initPageData().then(v => {
                            setnotActionConfirm(false)
                            SetAutoRenewalStatus(false)
                            if (res.code == 0) {
                                setSuccessDiaLog(true)
                            }
                        }).catch(err => {
                            setnotActionConfirm(false)
                        })
                    }, 3000);
                })
            }
        } else {
            paypalSubscriptionOp({ id: UserInfo.id }).then(res => {
                initPageData().then(v => {
                    setnotActionConfirm(false)
                    SetAutoRenewalStatus(false)
                    if (res.code == 0) {
                        setSuccessDiaLog(true)
                    }
                }).catch(err => {
                    setnotActionConfirm(false)
                })

            })
        }
    }

    const [autoRenewalStatus, SetAutoRenewalStatus] = useState(false)

    let payCode = ''
    if (PayData.payAccount && PayData.payTypeName != 'card') {
        payCode = PayData.payAccount
        if (payCode.indexOf('@')) {
            payCode = maskEmail(payCode)
        }
    }


    let expiresTime = formatDate(PayData.expiresTime)

    let endTime = formatEndDate(PayData.payTime)

    function formatEndDate(dateTimeString) {
        var dateTime = new Date(dateTimeString);
        var year = dateTime.getFullYear();
        var month = ("0" + (dateTime.getMonth() + 1)).slice(-2);
        var day = ("0" + dateTime.getDate()).slice(-2);
        var formattedDate = year + "-" + month + "-" + day;
        return formattedDate;
    }


    /**
     * 打开订阅弹窗
     */
    const handleOk = () => {
        SetAutoRenewalStatus(true)
    }
    /**
     * 关闭订阅弹窗
     */
    const handleCancel = () => {
        SetAutoRenewalStatus(false)
    }


    const generateRandomNumber = () => {
        var min = 100000; // 最小值（包含）
        var max = 999999; // 最大值（包含）
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const timeStr = TimeFormat(PayData.expiresTime)

    const ToCard = () => {
        // window.open(PayData.url, 'refox team', "menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1")
        document.title = PayData.url + '?token=' + generateRandomNumber()
    }

    /**
     * 日期格式化
     * @param {*} inputStr 
     * @returns 
     */
    function TimeFormat(inputStr) {
        const formattedStr = inputStr.replace("T", " ").substr(0, 19);
        return formattedStr
    }

    /**
     * 日期格式化
     * @param {*} dateString 
     * @returns 
     */
    function formatDate(dateString) {
        var date = new Date(dateString);
        var options = { month: 'short', day: 'numeric', year: 'numeric' };
        var formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    }
    /**
     * email保密
     * @param {String} email 需要加密的email
     * @returns 加密完成的email
     */
    function maskEmail(email) {
        var username = email.split("@")[0];
        var maskedUsername = username.substring(0, 3) + "*".repeat(username.length - 3);
        var domain = email.split("@")[1];
        var maskedEmail = maskedUsername + "@" + domain;
        return maskedEmail;
    }


    return (
        <div className={styles.order_record_main}>
            <CommonMainLayout>
                <div className={styles.pageMain}>
                    <div >
                        <h3 className={styles.subScriptionHead}>Your Subscription</h3>
                        <div className={styles.pageMainItem}>
                            <div>
                                <div className={styles.pageMainItemHead}>{PayData.name}</div>
                                <div className={styles.pageMainItemLabe}>Expires: {timeStr}</div>
                            </div>
                            {/* <button className={styles.pageMainItemButton}>Extend</button> */}
                        </div>
                    </div>
                    <br />
                    <div>
                        <h3 className={styles.subScriptionHead}>Payment Settings</h3>
                        <div className={styles.pageMainItem}>
                            <div>
                                <div className={styles.payType}>{PayData.payTypeName}</div>
                                <div className={styles.pageMainItemLabe}>{PayData.payTypeName == 'Paypal' ? payCode : "Click 'Edit' to view Your Payment details and more."}</div>
                            </div>
                            {PayData.payTypeName != 'Paypal' ? <button className={styles.pageMainItemButton} onClick={ToCard}>Edit</button> : <></>}
                        </div>
                    </div>
                    <br />
                    <div className={styles.pageMainItem}>
                        <div>
                            <div className={styles.pageMainItemHead}>Auto-renewal {PayData.status == 3 ? <span style={{ 'color': '#F84C4C' }}>OFF</span> : <span style={{ 'color': '#249FEC' }}>ON</span>}</div>
                            <div className={styles.pageMainItemLabe}>Enable auto-renewal to stay up-to-date.
                            </div>
                            {PayData.status == 3 ? <></> : <div className={styles.NextPay}>Next charge date on <span style={{ 'color': '#249FEC' }}>{PayData.nextChargeDate}</span></div>}
                        </div>
                        {PayData.edit ? <button className={styles.pageMainItemButton} onClick={() => { SetAutoRenewalStatus(true) }}>Edit</button> : <></>}
                    </div>
                    <br />
                    <div >
                        <h3 className={styles.subScriptionHead}>Invoices & receipts</h3>
                        <div className={styles.pageMainItem}>
                            <div>
                                <div className={styles.pageMainItemHead}>{PayData.name}</div>
                                <div className={styles.pageMainItemLabe}>{endTime}</div>
                            </div>
                            <button className={styles.pageMainItemButton} onClick={OpenInvoicesStatus}>View Details</button>
                        </div>
                    </div>
                </div>
            </CommonMainLayout>
            {/* 退订 */}
            <Modal title={PayData.status == 3 ? 'Turn on auto-renewal' : 'Turn off auto-renewal'} className={'renewalDialog'} visible={autoRenewalStatus} centered footer={null} onOk={handleOk} onCancel={handleCancel} maskClosable={false} >
                <div className={styles.subscriptionMain}>
                    <img src={subscription} className={styles.subscriptionImg} alt="" srcset="" />
                    {PayData.status == 3 ? <p className={styles.subscriptionText} >Your subscription will renew automatically on <b>{expiresTime}</b></p> : <p className={styles.subscriptionText}>Your subscription will expire on <b>{expiresTime}</b> without auto-renewal</p>}
                    <div className={styles.subscriptionBtns}>
                        <Button className={styles.subscriptionCancel} onClick={() => { handleCancel() }} disabled={notActionConfirm}>Cancel</Button >
                        <Button className={styles.subscriptionConfirm} onClick={() => { paypalSubscriptionOpFun() }} disabled={notActionConfirm} loading={notActionConfirm}>Confirm</Button >
                    </div>
                </div>
            </Modal>
            {/* 下载发票 */}
            {UserInfo ? <InvoicesDownload TaxInfo={TaxInfo} initTaxInfo={initTaxInfo} InvoicesStatus={InvoicesStatus} closeInvoicesStatus={closeInvoicesStatus} Order={PayData.orders} UserInfo={UserInfo} /> : <></>}

            <Modal title="Submitted Successfully" zIndex={5} width={400} className={'renewalDialog'} visible={SuccessDiaLog} centered footer={null} onCancel={CloseSuccessDiaLog} >
                <div className={styles.SuccessIconBox}>
                    <img src={SuccessIcon} alt="" className={styles.SuccessIcon} />
                    <p className={styles.SuccessIconTxt}>Submitted Successfully</p>
                </div>
            </Modal>
        </div>
    )
}

export default SubscriptionMain