import styles from './index.module.css'
import CommonImage from '@/components/CommonImage';
import NoticeIcon from '@/assets/images/ico_notice_notice.png';
import HomeBanner from '@/assets/images/202408291727.jpg';
import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { getNoticeList, getChangelog } from '@/api/home/home';
import useCounterModel from '@/model/user';
import CommonMainLayout from '@/components/CommonMainLayout';
import { getnoticeList } from '@/api/Faq'
import { Link } from 'react-router-dom';


const Dataformat = (times) => {
    return times.split(' ')[0]
}

// 更新历史记录组件
const NoticeList = (props) => {
    return (
        <div className={styles.noticeList}>
            <ul className={styles.noticeListul}>
                {props.noticeList.map(res => {
                    return (<li className={styles.noticeListitem} key={res.id}><span className={styles.times}>{Dataformat(res.updateTime)}</span><div className={styles.contens}> {res.content}</div></li>)
                })}
            </ul>
        </div>
    )
}


// 主体
const HomeFooter = () => {
    const [detail, setDetail] = useState({});
    const [noticeList, setnoticeList] = useState([])
    const handleOpenNewWindow = () => {
        window.open('https://www.refoxteam.com/', 'refox team', "menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1")
    }

    const getDetail = () => {
        getChangelog().then(res => {
            if (res.code == 0) {
                setDetail(res.data)
            }
        })
    }
    const { uid } = useCounterModel();

    const getnoticeListinfo = () => {
        getnoticeList().then(res => {
            setnoticeList(res.rows)
        })
    }
    const generateRandomNumber = () => {
        var min = 100000; // 最小值（包含）
        var max = 999999; // 最大值（包含）
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    const openview = () => {
        document.title = 'https://mailchi.mp/rewa/join-training-become-a-phone-repair-professional-now' + '?token=' + generateRandomNumber()
    }

    useEffect(() => {
        getDetail()
        getnoticeListinfo()
    }, [0])
    const element = (<div className={`${styles.home_footer}`}>
        <CommonMainLayout>
            <Row>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingRight: '10px' }}>
                    <div className='footer_left'>
                        {/* <div style={{ cursor: 'pointer' }} target='_blank' onClick={handleOpenNewWindow}>
                         */}

                        <div style={{ cursor: 'pointer' }} target='_blank' onClick={openview}>
                            {/* <Link to={'/getrewards?uid=' + uid}>
                               
                            </Link> */}
                            <img width={'100%'} src={HomeBanner} className={styles.img_banner} />
                            {/* <CommonImage width={'100%'} url={'https://rewa-new-shop.oss-ap-southeast-1.aliyuncs.com/mars/picture/20210819110115-iphone_lcd_oled_screen_replacement-png?x-oss-process=image/resize,h_415,w_570'} /> */}
                        </div>
                    </div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '10px' }}>
                    <div className={`${styles.footer_right_wrap}`}>
                        <div className={`clearfix ${styles.logshead}`}><CommonImage width={20} url={NoticeIcon} /><span className={styles.notice}>Update Logs</span><a href='/logs' className={`${styles.mymorebtn}`}>See More</a></div>
                        <div className={styles.changeLog}><NoticeList noticeList={noticeList} />
                        </div>
                    </div>
                </Col>
            </Row>
        </CommonMainLayout>
    </div>)
    return element
}
export default HomeFooter