import RegLayout from '@/components/RegAndForgot/RegLayout';
import { Form, Input, Button, notification } from 'antd';
import { RefoxForgetPwd, RefoxVerifyEmail } from '@/api/reg/reg';
import { useState } from 'react';

var timer = null

const ForgotMain = () => {
    // 按钮状态

    const [loading, setLoading] = useState(false);
    // 步骤标记
    const [step, setStep] = useState(1);

    const [btndisabled, setbtndisabled] = useState(false);
    // 保存输入并验证过的符合邮箱格式的邮箱记录，当再次查询前，先查询历史记录是否存在记录,格式 {email:xxx,verify:Boolean}
    const [historyEmail, setHistoryEmail] = useState([]);
    const onFinish = (values) => {
        setLoading(true);
        RefoxForgetPwd(values).then((res) => {
            notification['success']({ message: 'Tips', description: res.data });
            if (res.code == 0) {
                setStep(2);
            }
        });
    };
    const varifyEmail = (_rules, _value) => {

        if (!_value || _value.trim() == '') {
            return Promise.resolve();
        }
        let regExp = /[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/;
        if (regExp.test(_value)) {
            let hasEmailIdx = historyEmail.findIndex(h => h.email == _value);
            if (hasEmailIdx !== -1) {
                //  存在 历史记录中已经验证是否通过记录
                if (historyEmail[hasEmailIdx].verify) {

                }
                return historyEmail[hasEmailIdx].verify ? Promise.resolve() : Promise.reject('The mailbox is not registered!')
            }
            // 不存在则需要进行查询
            if (timer) {
                clearTimeout(timer)
            }
            let res
            return new Promise((resolve, reject) => {
                timer = setTimeout(() => {
                    RefoxVerifyEmail({ email: _value }).then((res) => {
                        setHistoryEmail([...historyEmail, { email: _value, verify: res.code == 500 }])
                        return res.code == 500 ? resolve(" ") : reject('The mailbox is not registered!')
                    });
                }, 500);
            })

            // 无论是否注册过，都必须将历史记录缓存下来


        }
        return Promise.reject(new Error('The mailbox format is incorrect'))
    }
    const element = (<RegLayout>
        {step == 1 ? (
            <>
                <p className={`cf5 size18`}>EMAIL VERIFICATION</p>
                <p className="size18">
                    Please enter your email address to reset your password.You will
                    receive an email shortly.
                </p>
                <Form
                    name="nest-messages"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item name="email" type="new-password" validateFirst rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'The mailbox format is incorrect' }, {
                        validateTrigger: 'onChange',
                        validator: (rule, value) =>
                            varifyEmail(rule, value),
                    },]}>
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} disabled={btndisabled} >
                            SUBMIT
                        </Button>
                    </Form.Item>
                </Form>
            </>
        ) : (
            <>
                <p className={`cf5 size18`}>FORGET YOUR PASSWORD ?</p>
                <p className={`size18`}>
                    Success to send password-reset email. Please check your email for
                    your password-reset link.
                </p>
            </>
        )}
    </RegLayout>);
    return element
}

export default ForgotMain