import { Modal, Checkbox, Input, Button, Radio, message } from 'antd'
import { useState } from 'react';
import styles from './Questionnaire.module.css'
import { QuestionnaireFrom } from '@/api/user/user'

const { TextArea } = Input;



const CheckouItem = ({ title }) => {
    return (
        <div>
            <input type='checkBox' className={styles.CheckBoxForInput}>

            </input>

        </div>
    )
}

//    /** 用户id */
//    @Excel(name = "用户id")
//    private Long uid;

//    /** 建议类型 0 用户失效后 1 用户续费后 */
//    @Excel(name = "建议类型 0 用户失效后 1 用户续费后")
//    private Integer type;

//    /** 用户的选项 */
//    @Excel(name = "用户的选项")
//    private String userOption;

//    /** 用户建议 */
//    @Excel(name = "用户建议")
//    private String useSuggest;

//    /** 是否愿意推荐 0 不愿意 1 愿意 */
//    @Excel(name = "是否愿意推荐 0 不愿意 1 愿意")
//    private Integer recommendFlag;

// /** 其他点位图描述 */
// @Excel(name = "其他点位图描述")
// private String otherDesc;

// 调查问卷类型1（未续费用户）
const Module1 = ({ close, uid }) => {

    const [lodding, setlodding] = useState(false)
    // 用户选项
    const [userOption, setuserOption] = useState([])
    // 选项备注
    const [userOptionRemark, setUserOptionRemark] = useState()
    // 用户建议
    const [useSuggest, setUseSuggest] = useState()
    // 设置用户选项
    const onChange = (e) => {
        setuserOption(e)
    };
    // 设置用户选项
    const onChangeRemark = (e) => {
        setUserOptionRemark(e.target.value)
    };
    // 设置用户建议
    const onChangeUseSuggest = (e) => {
        setUseSuggest(e.target.value)
    };
    // 提交from表单
    const SendFromData = () => {
        if (userOption.length != 0) {
            setlodding(true)
            let params = {
                uid: uid,
                type: 0,
                userOption: userOption.join(','),
                otherDesc: userOptionRemark,
                useSuggest: useSuggest,
            }
            QuestionnaireFrom(params).then(res => {
                if (res.code == 0) {
                    message.success('Thanks for your feedback!');
                }
                setlodding(false)
                close()
            })

        } else {
            message.info('please Select a reason');
        }
    }
    return (
        <div>
            <h5 className={styles.FromBoxHead}>
                <b> We’re sad to see you go!</b><br></br>
                Let us know why you are no longer subscribing to REFOX Bitmap.
            </h5>
            <div className={`${styles.FromItemBox} CheckBoxForInput`}>
                <p className={styles.optionHead}>Select a reason</p>
                <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                    <div className={styles.FromItem}>
                        <Checkbox value="A" >Too expensive</Checkbox>
                    </div>
                    <div className={styles.FromItem}>
                        <Checkbox value="D" >Missing feature I need</Checkbox>
                    </div>
                    <div className={styles.FromItem}>
                        <Checkbox value="B" >Lack of Model Content</Checkbox>
                    </div>
                    <div className={styles.FromItem}>
                        <Checkbox value="C" >Hard to Use\Technical Issue</Checkbox>
                    </div>
                    <div className={styles.FromItem}>
                        <Checkbox value="E" >Using other bitmap (Please specify)</Checkbox>
                    </div>
                </Checkbox.Group>
            </div>
            {userOption.indexOf('E') >= 0 ? <div className={styles.otherBitmap}>
                <TextArea rows={2} placeholder="" value={userOptionRemark} onChange={onChangeRemark} />
            </div> : <></>}
            <div>
                <h3 className={styles.linkHead}>Anything else you’d like to share?</h3>
                <TextArea rows={4} placeholder="I have feedback on... (Optional)" value={useSuggest} onChange={onChangeUseSuggest} />
            </div>
            <div className={styles.changeaccountbtnBox}>
                <Button className={styles.changeaccountbtn} onClick={close} loading={lodding}>Cancel</Button>
                <Button className={styles.changeaccountbtnbySubmit} onClick={SendFromData} loading={lodding}>Sumbit</Button>
            </div>
            <p className={styles.NevermindText}> <span className={styles.toCancel} onClick={close} >Nevermind, i don’t want to cancel</span></p>
        </div>
    )
}

// 调查问卷类型2（续费用户）
const Module2 = ({ close, uid }) => {
    const [lodding, setlodding] = useState(false)
    // 用户选项
    const [userOption, setuserOption] = useState([])
    // 选项备注
    const [userOptionRemark, setUserOptionRemark] = useState()
    // 用户建议
    const [useSuggest, setUseSuggest] = useState('')
    // 是否可推荐
    const [recommendFlag, setRecommendFlag] = useState('')

    // 是否可推荐
    const ChangerecommendFlag = (e) => {
        setRecommendFlag(e.target.value)
    }
    // 设置用户选项
    const onChange = (e) => {
        setuserOption(e)
    };
    // 设置用户选项
    const onChangeRemark = (e) => {
        setUserOptionRemark(e.target.value)
    };
    // 设置用户建议
    const onChangeUseSuggest = (e) => {
        setUseSuggest(e.target.value)
    };
    // 提交from表单
    const SendFromData = () => {
        if (userOption.length != 0) {
            if (recommendFlag === '') {
                message.info('Thanks for your feedback!');
                return false
            }
            setlodding(true)
            let params = {
                uid: uid,
                type: 1,
                userOption: userOption.join(','),
                otherDesc: userOptionRemark,
                useSuggest: useSuggest,
                recommendFlag: recommendFlag
            }
            QuestionnaireFrom(params).then(res => {
                if (res.code == 0) {
                    message.success('Thank you for your valuable comments and suggestions');
                }
                setlodding(false)
                close()
            })

        } else {
            message.info('please Select your using feedback');
        }
    }
    return (
        <div>
            <h5 className={styles.FromBoxHead}>
                <b> Good to see you here!</b><br></br>
                We are eager to improve our product and every bit of feedback helps!
            </h5>
            <h3 className={styles.linkHead}>Select your using feedback</h3>
            <div className={`${styles.FromItemBox} CheckBoxForInput`}>
                <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                    <div className={styles.FromItem}>
                        <Checkbox value="A">The pricing is OK</Checkbox>
                    </div>
                    <div className={styles.FromItem}>
                        <Checkbox value="D">Fast Updates</Checkbox>
                    </div>
                    <div className={styles.FromItem}>
                        <Checkbox value="B">Content meets my needs</Checkbox>
                    </div>
                    <div className={styles.FromItem}>
                        <Checkbox value="E">Better than other bitmap</Checkbox>
                    </div>
                    <div className={styles.FromItem}>
                        <Checkbox value="C">Easy to Use</Checkbox>
                    </div>
                    <div className={styles.FromItem}>
                        <Checkbox value="F">Others (Please specify)</Checkbox>
                    </div>
                </Checkbox.Group>
            </div>
            {userOption.indexOf('F') >= 0 ? <div className={styles.otherBitmap}>
                <TextArea rows={2} placeholder="" value={userOptionRemark} onChange={onChangeRemark} />
            </div> : <></>}
            <div className={`${styles.RadioBox} CheckBoxForRadio`}>
                <h3 className={`${styles.linkHead} `}>Would you like to recommend REFOX Bitmap to your friend?</h3>
                <Radio.Group onChange={ChangerecommendFlag} value={recommendFlag} className={styles.radioBox}>
                    <div className={styles.radioItem}>
                        <Radio value={1}>Yes</Radio>
                    </div>
                    <div className={styles.radioItem}>
                        <Radio value={0}>No</Radio>
                    </div>
                </Radio.Group>
            </div>
            <div>
                <h3 className={styles.linkHead}>Anything else you’d like to share?</h3>
                <TextArea rows={4} placeholder="I have feedback on... (Optional)" value={useSuggest} onChange={onChangeUseSuggest} />
            </div>
            <div className={styles.changeaccountbtnBox}>
                <Button className={styles.changeaccountbtn} onClick={close} loading={lodding}>Cancel</Button>
                <Button className={styles.changeaccountbtnbySubmit} onClick={SendFromData} loading={lodding}>Sumbit</Button>
            </div>
            {/* <p className={styles.NevermindText}>Nevermind, <span className={styles.toCancel} onClick={close}>i don’t want to cancel</span></p> */}
        </div>
    )
}

const Questionnaire = ({ status, type, uid }) => {
    const [PopState, setPopState] = useState(status)

    const ClosePopup = () => {
        setPopState(false)
    }
    return (
        <div>
            <Modal
                footer={null}
                className={'FeedbackPopUp'}
                title="Give Feedback"
                width="580px"
                centered
                visible={PopState}
                onOk={() => { }}
                onCancel={() => setPopState(false)}
            >
                {
                    type == 1 ? <Module1 close={ClosePopup} uid={uid} /> : <></>
                }
                {
                    type == 2 ? <Module2 close={ClosePopup} uid={uid} /> : <></>
                }
            </Modal>
        </div>
    )
}
export default Questionnaire