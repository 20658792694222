import PayResult from '@/components/PayResult'
import { useEffect } from 'react'
import { valitycapturefun } from '@/api/home/home'
const PaySuccess = () => {

    useEffect(() => {

        const query = new URLSearchParams(window.location.search);
        const queryParams = Object.fromEntries(query.entries());
        let sessionId
        if (queryParams['session_id']) {
            sessionId = queryParams['session_id']
        }

        window.sessionStorage.removeItem('isSendQuestionnaire')
        let orderNo = window.localStorage.getItem('orderNo')
        if (orderNo) {
            let params = { orderNo: orderNo }
            if (sessionId) {
                params.sessionId = sessionId
            }
            valitycapturefun(params).then(res => {
                window.localStorage.removeItem('orderNo')
            })
        }
    })
    const element = <PayResult resultType={1} />
    return element
}
export default PaySuccess