import styles from './index.module.css';
import { Result, Button } from 'antd';
import { useEffect, useState } from 'react';
import { dealToken } from '@/plugins/utils';
import CommonMainLayout from '@/components/CommonMainLayout';
const PayResult = (props) => {
    const { resultType } = props;
    const [count, setCount] = useState(5);
    const uid = dealToken()
    const failFun = () => {
        let num = count
        let timer = setInterval(() => {
            if (num > 0) {
                setCount(--num)
            } else {
                clearInterval(timer);
                window.location.href = `/?uid=${uid}`
            }
        }, 1000);
    }
    const drumpHome = () => {
        window.location.href = `/?uid=${uid}`
    }
    useEffect(() => {
        failFun()
    }, [1])
    const element = (<div className={styles.pay_main}>
        <CommonMainLayout>
            <div className={`${styles.main}`}>
                {resultType == 1 ? <Result
                    status="success"
                    title="Purchase result"
                    subTitle={`The purchase is successful, Jump home after ${count}s, please wait...`}
                    extra={[
                        <Button type="primary" onClick={drumpHome} key="console">
                            Go Home
                        </Button>,
                    ]}
                /> : resultType == 0 ? <Result
                    status="error"
                    title="Purchase result"
                    subTitle={`Purchase failed, please check if the activation code is available, Jump home after ${count}s`}
                    extra={[
                        <Button type="primary" onClick={drumpHome} key="console">
                            Go Home
                        </Button>,
                    ]}
                ></Result> : <Result
                    status="info"
                    title="Purchase result"
                    subTitle={`Payment cancelled, Jump home after ${count}s`}
                    extra={[
                        <Button type="primary" onClick={drumpHome} key="console">
                            Go Home
                        </Button>,
                    ]}
                ></Result>}
            </div>
        </CommonMainLayout>

    </div>);
    return element
}
export default PayResult