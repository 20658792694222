import styles from './index.module.css';
import { Divider } from 'antd';
import CommonImage from '@/components/CommonImage';
import { useRef, useState, useEffect } from 'react'
import Paly from '@/assets/images/play.svg'
const UserManualMain = () => {
    const videoRef = useRef(null);
    const [play, setPlay] = useState(false)

    const handlePlay = () => {
        setPlay(true)
        videoRef.current.play()
    }
    const listeningVideo = () => {
        videoRef.current.addEventListener('pause', function () {
            setPlay(false);
        });
        videoRef.current.addEventListener('play', function () {
            setPlay(true);
        });
    }
    useEffect(() => {
        listeningVideo()
    })
    const element = (<div className={styles.user_manual_main}>
        <div className={`common_main ${styles.main}`}>
            <h4>User Manual</h4>
            <Divider />
            <div className={styles.video_wrap}>
                {!play ? <div className={styles.video_mask}>
                    <div className={styles.btn_wrap} onClick={handlePlay}><CommonImage url={Paly} width={100} /></div>
                </div> : false}
                <video ref={videoRef} src="https://refox.oss-ap-southeast-1.aliyuncs.com/refox.mp4" controls width={838} height={500} />
            </div>
        </div>
    </div>)
    return element
}
export default UserManualMain