import styles from './index.module.css';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { dealToken } from '@/plugins/utils';
import { EditFilled, HomeFilled } from '@ant-design/icons';

const CaseTab = () => {
    let uid = dealToken();
    const tabList = [{ icon: <EditFilled />, title: '案例', link: '/refoxCase?uid=' + uid, type: 'link' }]
    const element = (<div className={styles.head_tab}>
        <Row>
            <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={`common_main ${styles.head_tab_main}`}>
                    {tabList.map(item => (<Link to={item.link} key={item.title} className={styles.tab_link}>{item.icon}<span className={styles.word}>{item.title}</span></Link>))}
                    <Link to={`/?uid=${uid}`} className={`fr ${styles.home}`}><HomeFilled color='#3a3a3a' /></Link>
                </div>
            </Col>
        </Row>

    </div>)
    return element
}
export default CaseTab