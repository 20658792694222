import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import App from '@/App.js';
import Home from '@/pages/index';
import OrderRecord from '@/pages/order-record';
import News from '@/pages/news';
import Faq from '@/pages/faq';
import UserManual from '@/pages/user-manual';
import PaySuccess from '@/pages/pay-success';
import PayFail from '@/pages/pay-fail';
import PayCancel from '@/pages/pay-cancel';
import UserInfo from '@/pages/user/user-info';
import ChangePassword from '@/pages/user/change-password'
import NotFound from '@/pages/404';
import Pdf from '@/pages/pdf';
import FeedbackPage from '@/pages/feedback';
import RefoxCase from '@/pages/refoxCase';
import Register from "@/pages/register";
import RegisterNext from '@/pages/register-next';
import Forget from '@/pages/forget';
import ForgetNext from '@/pages/forget-next';
import SwitchAccounts from '@/pages/switch-accounts'
import Logs from '@/pages/logs'
import Getrewards from '@/pages/getrewards'
import Promotedetails from '@/pages/Promotedetails'
import Subscription from '@/pages/Subscription'
const Routes = () => {
    const element = (<Router>
        <Switch>
            <App>
                <Route exact path="/Subscription" component={Subscription} />
                <Route exact path="/news/:id" component={News} />
                <Route exact path="/getrewards" component={Getrewards} />
                <Route exact path="/promotedetails" component={Promotedetails} />
                <Route exact path="/order-record" component={OrderRecord} />
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/user-info" component={UserInfo} />
                <Route exact path="/feedback" component={FeedbackPage} />
                <Route exact path="/refoxCase" component={RefoxCase} />
                <Route exact path="/change-password" component={ChangePassword} />
                <Route exact path="/user-manual" component={UserManual} />
                <Route exact path="/pay-success" component={PaySuccess} />
                <Route exact path="/pay-fail" component={PayFail} />
                <Route exact path="/pay-cancel" component={PayCancel} />
                <Route exact path="/pdf" component={Pdf} />
                <Route exact path="/switch-accounts" component={SwitchAccounts} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/register-next" component={RegisterNext} />
                <Route exact path="/forget" component={Forget} />
                <Route exact path="/forget-next" component={ForgetNext} />
                <Route exact path="/logs" component={Logs} />
                <Route exact path="/404" component={NotFound} />
                <Route exact path="/" component={Home} />
            </App>
        </Switch>
    </Router>)
    return element
}
export default Routes