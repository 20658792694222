import styles from './index.module.css';
import useCounterModel from '@/model/user';
import { Form, Input, Button, Image, message, Row, Col } from 'antd';
import { useState, useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import UploadAvatar from '@/components/UploadAvatar';
import CommonMainLayout from '@/components/CommonMainLayout';
import HeadPic from '@/assets/images/head.png';
import { UserUpdateReq } from '@/api/user/user';
import copy from 'copy-to-clipboard';
const UserUpdate = () => {
    const { userInfo } = useCounterModel();
    const [form] = Form.useForm();
    const [avatar, setAvatar] = useState('')
    const [loading, setLoading] = useState(false)
    const onFinish = (values) => {
        let params = { id: userInfo.id, nickName: values.nickName };
        setLoading(true)
        UserUpdateReq(params).then(res => {
            setLoading(false)
            if (res.code == 0) {
                message.success('Successfully modified');
                window.location.reload()
            } else {
                message.error('Fail to edit')
            }
        })
    };
    const onValuesChange = () => {
        console.log('我被触发了')
    }
    /**
     *  允许loginName 复制到粘贴板上,使用 copy-to-clipboard 插件
     * */
    const copyToBoard = (name) => {
        if (copy(name)) {
            message.success('loginName copied to the pasteboard')
        } else {
            message.error('Failed to copy loginName to pasteboard')
        }
    }
    useEffect(() => {
        setAvatar(userInfo.icon || HeadPic)
    }, [userInfo])
    const element = (<div className={styles.user_update}>
        <CommonMainLayout>
            <div className={`${styles.main}`}>
                {userInfo.nickName && <Row>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form
                            name="basic"
                            form={form}
                            labelAlign="left"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ nickName: userInfo.nickName, loginName: userInfo.loginName }}
                            onFinish={onFinish}
                            onValuesChange={onValuesChange}
                            autoComplete="off"
                            className={styles.form}
                        >
                            <Form.Item
                                label="LoginName"
                                name="loginName"
                                maxLength={50}
                            >
                                <p className={`bold ${styles.loginName}`}>{userInfo.loginName}<div onClick={() => copyToBoard(userInfo.loginName)} className={styles.copybutton}>CopyName</div></p>
                            </Form.Item>
                            {/* <Form.Item
                                label="Avatar"
                                name="icon"
                            >
                                <UploadAvatar setAvatar={setAvatar} >
                                    <div style={{ width: '148px' }}>
                                        <Image
                                            preview={false}
                                            width={148}
                                            src={avatar}
                                        />
                                    </div>
                                </UploadAvatar>
                            </Form.Item> */}
                            <Form.Item
                                label="Username"
                                name="nickName"
                                maxLength={50}
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 6, span: 6 }}>
                                <Button type="primary" htmlType="submit" loading={loading} block size='large'>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>}
            </div>
        </CommonMainLayout>

    </div>)
    return element
}

export default UserUpdate