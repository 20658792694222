import styles from './index.module.css';
import { Modal, Input, Button, message } from 'antd';
import { updateaccountapi } from '@/api/rewards/index'
import { useState } from 'react'
const msg01 = 'The Paypal account cannot be empty!'

const Changemodule = ({ ischangeAccount, hidechangemodule, uid, getuserinfo }) => {

    const [newpaypal, setpaypal] = useState('')

    const [showmsg, setshowmsg] = useState(false)

    const [lodding, setlodding] = useState(false)

    const handleCancel = () => {
        hidechangemodule(false);
    };

    const changepaypal = (e) => {
        let value = e.target.value.replace(/(^\s*)|(\s*$)/g, '')
        setpaypal(value)
        if (value == '') {
            setshowmsg(true)
        } else {
            setshowmsg(false)
        }
    }

    const updateuser = () => {
        getuserinfo()
    }

    const updateaccountfun = () => {
        setlodding(true)
        if (newpaypal == '') {
            setshowmsg(true)
            setlodding(false)
            return
        }
        let parms = {}
        parms.account = newpaypal
        parms.uid = uid
        let that = this
        updateaccountapi(parms).then(res => {
            if (res.code == 0) {
                updateuser()
                message.success('update success');
                setpaypal('')
            } else {
                message.error('Update failed');
            }
            setlodding(false)
            handleCancel()
        })
    }

    return (
        <Modal title="Edit Withdraw Account" visible={ischangeAccount} onCancel={handleCancel} centered footer={null} className={'accountDialogmodalcss'} >
            <div className={styles.changeaccount}>
                <Input value={newpaypal} placeholder="Please enter your Paypal account" onChange={(e) => changepaypal(e)} className={styles.changeaccountinput} />
                <p className={styles.errormsg}>{showmsg ? msg01 : ''}</p>
                <p className={styles.changeaccounttxt}>Only Paypal support</p>
                <Button className={styles.changeaccountbtn} onClick={updateaccountfun} loading={lodding}>Save</Button>
            </div>
        </Modal>
    )
}

export default Changemodule