import styles from './index.module.css';
import './index.css'
import { Tree, Input } from 'antd';
import { useState, useEffect } from 'react';
import { getCaseCategory, getCaseDetail } from '@/api/case/case';
import useCounterModel from '@/model/user';
import { dealToken } from '@/plugins/utils';
import { getUserInfo } from '@/api/user/user';
import { Decrypt } from '@/plugins/utils';
import Mask from '@/components/Mask';
const { Search } = Input;
const { DirectoryTree } = Tree;
const x = 3;
const y = 2;
const z = 1;
const gData = [];
const generateData = (_level, _preKey, _tns) => {
    const preKey = _preKey || '0';
    const tns = _tns || gData;

    const children = [];
    for (let i = 0; i < x; i++) {
        const key = `${preKey}-${i}`;
        tns.push({ title: key, key });
        if (i < y) {
            children.push(key);
        }
    }
    if (_level < 0) {
        return tns;
    }
    const level = _level - 1;
    children.forEach((key, index) => {
        tns[index].children = [];
        return generateData(level, key, tns[index].children);
    });
};
generateData(0);

const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }
    return parentKey;
};


const CaseTree = (props) => {
    const { className, setDetail } = props;
    const { userInfo, setUser, loginFail, setLogin, setTokenFun } = useCounterModel();
    const { id } = userInfo;
    let uid = dealToken();
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryDataList, setCategoryDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const getCategoryFun = () => {
        getCaseCategory({ type: 1 }).then(res => {
            res.data.map(item => {
                item.title = item.name;
                item.key = item.name
            })
            let data = handleTree(res.data, 'id', 'pid');
            setCategoryList(data)
            // let newData = generateList(data, categoryDataList)
            // console.log(data, newData)
        })
    }
    const getUserInfoFun = () => {
        if (uid) {
            setTokenFun(uid)
            setLoading(true)
            getUserInfo({ uid: uid }).then(res => {
                setLoading(false)
                if (res.code == 0) {
                    setUser(res.data)
                    setLogin(false)
                } else {
                    setLogin(true)
                }
            })
        } else {
            // 报错，未找到用户信息
            setLogin(true)
        }
    }
    useEffect(() => {
        getUserInfoFun()
        getCategoryFun()
    }, [1])
    const generateList = (data, dataArr) => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const { key, title } = node;
            dataArr.push({ key, title: title });
            if (node.children) {
                generateList(node.children, dataArr);
            }
        }
    };
    /**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
    const handleTree = (data, id, parentId, children, rootId) => {
        id = id || 'id'
        parentId = parentId || 'parentId'
        children = children || 'children'
        rootId = rootId || Math.min.apply(Math, data.map(item => { return item[parentId] })) || 0
        //对源数据深度克隆
        const cloneData = JSON.parse(JSON.stringify(data))
        //循环所有项
        let treeData = cloneData.filter(father => {
            //返回每一项的子级数组
            let branchArr = cloneData.filter(child => (father[id] === child[parentId]));
            // branchArr.length > 0 ? (father.children = branchArr) : '';
            if (branchArr.length > 0) {
                father.children = branchArr
            }
            //返回第一层
            return (father[parentId] === rootId);
        });
        return treeData != '' ? treeData : data;
    }
    const onExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys);
        setAutoExpandParent(false)
    }
    const onChange = (e) => {
        const { value } = e.target;
        const expandedKeys = categoryDataList.map(item => {
            if (item.title.indexOf(value) > -1) {
                return getParentKey(item.key, categoryList);
            }
            return null;
        }).filter((item, i, self) => item && self.indexOf(item) === i);
        setExpandedKeys(expandedKeys);
        setSearchValue(value);
        setAutoExpandParent(true)
    }
    const onSelect = (selectedKeys, info) => {
        if (!id) {
            //如果是未登录状态，不给任何点击操作发请求
            return false
        }
        if (!info.node.children) {
            setLoading(true)
            // 当没有子级存在时，才可以找到对应的数据发起请求
            getCaseDetail({ title: selectedKeys[0], type: 1 }).then(res => {
                setLoading(false)
                if (res.code == 0) {
                    setDetail(res.data ? Decrypt(res.data) : '')
                }
            })
        }

    };
    const loop = data =>
        data.map(item => {
            const index = item.title.indexOf(searchValue);
            const beforeStr = item.title.substr(0, index);
            const afterStr = item.title.substr(index + searchValue.length);
            const title =
                index > -1 ? (
                    <span>
                        {beforeStr}
                        <span className="site-tree-search-value">{searchValue}</span>
                        {afterStr}
                    </span>
                ) : (
                    <span>{item.title}</span>
                );
            if (item.children) {
                return { title, key: item.key, children: loop(item.children) };
            }
            return {
                title,
                key: item.key,
            };
        });
    const element = (<div className={`${styles.case_tree} ${className} case_tree`}>
        {loading ? <Mask /> : false}
        <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={onChange} />
        <DirectoryTree
            showIcon={false}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            treeData={loop(categoryList)}
            onSelect={onSelect}
            titleRender={(nodeData) => <div className={styles.title} title={nodeData.key}>{nodeData.title}</div>}
        />
    </div>);
    return element
}
export default CaseTree