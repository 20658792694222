import styles from './index.module.css'
import InvoicesForm from '@/components/InvoicesForm'
import { Modal, Button } from 'antd';
import { useState } from 'react';
import DowloadIcon from '@/assets/images/DowloadIcon.svg'
import { downloadInvoice } from '@/api/Subscription'
const InvoicesDownload = ({ TaxInfo, initTaxInfo, InvoicesStatus, closeInvoicesStatus, Order, UserInfo }) => {
    const [InvoicesFormStatus, setInvoicesFormStatus] = useState(false)

    const [downloadStatus, setDownloadStatus] = useState(false)

    /**
     * 打开下载弹窗
     */
    const handleOk = () => {
        setInvoicesFormStatus(true)
    }
    /**
     * 关闭下载弹窗
     */
    const handleCancel = () => {
        setInvoicesFormStatus(false)
    }

    const downloadFile = (url, filename) => {
        var link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const extractFilenameFromURL = (url) => {
        var filename = url.substring(url.lastIndexOf('/') + 1);
        return filename;
    }

    const generateRandomNumber = () => {
        var min = 100000; // 最小值（包含）
        var max = 999999; // 最大值（包含）
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const downloadFun = (params) => {

        if (!TaxInfo || !TaxInfo.id) {
            handleOk()
            return false
        }
        setDownloadStatus(true)
        downloadInvoice({ id: params.id, taxInfoId: TaxInfo.id }).then(res => {
            if (res.code == 0) {
                // let FileName = extractFilenameFromURL(res.msg)
                // downloadFile(res.msg, FileName)
                document.title = res.msg + '?token=' + generateRandomNumber()
            }
            setDownloadStatus(false)
        }).catch(err => {
            setDownloadStatus(false)
        })
    }
    return (
        <div>
            <InvoicesForm InvoicesFormStatus={InvoicesFormStatus} defaultData={TaxInfo} handleCancel={handleCancel} initTaxInfo={initTaxInfo} />
            <Modal zIndex={4} title="Invoices" className={'renewalDialog'} visible={InvoicesStatus} centered footer={null} onCancel={closeInvoicesStatus} >
                <div className={styles.subscriptionMain}>
                    <div className={styles.InvoicesDownloadEmail}>
                        Receive Email <br />
                        Invoices and billing notifications will automatically send to:<br />
                        <span className={styles.InvoicesDownloadtxt}>{UserInfo.email}</span>
                    </div>
                    {TaxInfo && TaxInfo.id ? <div className={styles.InvoicesDownloadEmail2}>
                        <span className={styles.InvoicesDownloadtxt3}>Invoice Details</span><span className={styles.InvoicesDownloadtxt2} onClick={() => { handleOk() }}>Edit</span><br />
                        Name: {TaxInfo.name}<br />
                        Address: {TaxInfo.address}<br />
                        Company Name: {TaxInfo.company}<br />
                        Tax Number: {TaxInfo.taxNumber}
                    </div> : <div className={styles.InvoicesDownloadEmail2}>
                        <button className={styles.AddInvoices} onClick={() => { handleOk() }}>Add Invoice Details</button>
                    </div>}
                    <table className={styles.InvoicesDownloadTable}>
                        <thead className={styles.InvoicesDownloadTableThead}>
                            <tr>
                                <td>Status</td>
                                <td>Issue date</td>
                                <td>Amount</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody className={styles.InvoicesDownloadTableTBody}>
                            {Order.map(res => {
                                return (
                                    <tr key={res.id}>
                                        <td>Paid</td>
                                        <td>{res.payTime}</td>
                                        <td>US${res.totalPrice}</td>
                                        <td><Button disabled={downloadStatus} onClick={() => { downloadFun(res) }} type="text" className={styles.InvoicesDownloadBtn} danger><img className={styles.InvoicesDownloadImg} src={DowloadIcon}></img>
                                        </Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </div>
    )
}

export default InvoicesDownload