import styles from './index.module.css';
import { Table, Space, Button, Divider, Spin, message, Modal } from 'antd';
import useCounterModel from '@/model/user';
import { useEffect, useState } from 'react';
import PayDialog from '@/components/PayDialog'
import { getUserOrderList, CancelOrder, ContinueOrder } from '@/api/user/user';
import CommonMainLayout from '@/components/CommonMainLayout';
import { createOrderInfo } from '@/api/home/home';
import { dealToken } from '@/plugins/utils';
import { getUserInfoapi } from '@/api/rewards/index'
import payIcon from '@/assets/images/payIcon.png';
const dealStatus = (status) => {
    switch (status) {
        case 0:
            // 待支付
            return 'Pending Payment';
        case 1:
            // 已支付
            return 'Payment successful';
        case 2:
            // 支付待确认
            return 'Payment Pending';
        case 3:
            // 已过期 未支付
            return 'Expired';
        case 4:
            // 已取消
            return 'Order Cancelled';
        case 5:
            // 已退款
            return 'Have a refund';
    }
}
const OrderRecordMain = () => {
    const columns = [
        {
            title: 'Creation Time',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center',
            className: 'min-heighttabel'
            // render: text => <a>{text}</a>,
        },
        {
            title: 'Payment Method',
            dataIndex: 'payTypeName',
            key: 'payTypeName',
            align: 'center'
        },
        {
            title: 'Product',
            dataIndex: 'productName',
            key: 'productName',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: status => <span>{dealStatus(status)}</span>
        },
        {
            title: 'Exipre Date',
            dataIndex: 'expireTime',
            key: 'expireTime',
            align: 'center',
        },
        {
            title: 'Deduct Price',
            dataIndex: 'deductPrice',
            key: 'deductPrice',
            align: 'center',
            render: price => price ? <span>${price}</span> : <span>{price}</span>
        },
        {
            title: 'Total Price',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            align: 'center',
            render: (text, record, index) => text ? <span>{record.symbol}{text}</span> : <span>{text}</span>
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    {record.status == 0 ? <div><Button type="primary" block className={styles.btn_pay} onClick={() => continueOrderFun(record.id)}>To Pay</Button><Button block className={styles.btn_cancel} onClick={() => cancelOrderFun(record.id)}>Cancel Order</Button></div> : false}
                </Space>
            ),
        },
    ];
    const [payLoading, setPayLoading] = useState(false);
    const [payDetail, setPayDetail] = useState(null);
    const [data, setData] = useState([]);
    const [payFlag, setPayFlag] = useState(false);
    const { userInfo } = useCounterModel();
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState();
    const [userinfos, setuserinfo] = useState({})
    const [paypalkey, setpaypalkey] = useState(0)

    const [reloadPage, setReloadPage] = useState(false)
    const closeReloadModal = () => {
        setReloadPage(false)
    }
    const ReloadPage = () => {
        window.location.reload()
    }

    let uids = dealToken();
    const continueOrderFun = (id) => {
        setpaypalkey(1)
        updateduserinfo()
        setLoading(true)
        ContinueOrder({ id }).then(res => {
            setLoading(false)
            if (res.code == 0) {
                setOrderId(id)
                setPayFlag(!payFlag)
                setPayDetail(res.data)
            }
        })
    }
    const updateduserinfo = () => {
        getUserInfoapi(uids).then(res => {
            if (res.code == 0) {
                setuserinfo(res.data)
            }

        })
    }
    const generateRandomNumber = () => {
        var min = 100000; // 最小值（包含）
        var max = 999999; // 最大值（包含）
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const handleToPay = (params) => {
        let newParams = { ...params, userId: userInfo.id, orderId: orderId }
        // console.log(newParams)
        createOrderInfo(newParams).then(res => {
            if (res.code == 0) {
                // if (params.paymentMethodId == 3) {
                //     setReloadPage(true)
                //     document.title = res.data.url + '?token=' + generateRandomNumber()
                // } else {
                //     window.location.href = res.data.url
                // }
                setReloadPage(true)
                document.title = res.data.url + '?token=' + generateRandomNumber()
            }
        })
    }
    const cancelOrderFun = (id) => {
        setLoading(true)
        CancelOrder({ id }).then(res => {
            setLoading(false)
            if (res.code == 0) {
                message.success('Order canceled successfully')
                getList()
            } else {
                message.warning('Order cancellation failed')
            }

        })
    }
    const getList = () => {
        if (!userInfo.id) {
            return false
        }
        setLoading(true)
        getUserOrderList({ loginName: userInfo.loginName }).then(res => {
            setLoading(false)
            if (res.code == 0) {
                setData(res.data)
            }
        })
    }

    useEffect(() => {
        if (uids) {
            updateduserinfo()
        }
        getList()
    }, [userInfo])
    const element = (<div className={styles.order_record_main}>
        <CommonMainLayout>
            <div className={`${styles.main}`}>
                <div className={styles.title}>Order Record</div>
                <Divider />
                <Spin tip="Loading..." spinning={loading}>
                    <Table columns={columns} rowKey={record => record.id} dataSource={data} />
                </Spin>
                {payDetail ? <PayDialog key={paypalkey} payDetail={payDetail} userinfo={userinfos} payFlag={payFlag} payLoading={payLoading} setPayLoading={setPayLoading} handleToPay={handleToPay} setPayFlag={setPayFlag} setpaypalkey={setpaypalkey} /> : false}
            </div>
            {/* 刷新页面 */}
            <Modal visible={reloadPage} footer={null} title={'Payment Processing'} onCancel={closeReloadModal} className={'reloadPageCss'} maskClosable={false}>
                <div>
                    <img src={payIcon} className={styles.reloadPageIcon}></img>
                    <p className={styles.reloadPageText}>If you have completed the payment,<br></br> Please click here to update your order status.</p>
                    <button className={styles.reloadPageButton} onClick={ReloadPage}>Update to Complete</button>
                </div>
            </Modal>
        </CommonMainLayout>
    </div>)
    return element
}

export default OrderRecordMain