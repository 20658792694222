import '@/App.css';
import Head from '@/components/Head';
import { Layout, Result } from 'antd';

import { Helmet } from 'react-helmet';
import useCounterModel from '@/model/user';
import { useHistory } from 'react-router-dom';
import CaseTab from '@/components/CaseTab'
const { Content } = Layout;
function App(props) {
  const histiry = useHistory()
  const arr = ['/feedback', '/refoxCase', '/register', '/register-next', '/forget', '/forget-next'];
  const needTab = ['/refoxCase'];
  const noLayout = ['/register', '/register-next']
  return (
    <div style={{ height: '100%' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Refox App</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      {arr.includes(histiry.location.pathname) ? false : <Head />}
      {needTab.includes(histiry.location.pathname) ? <CaseTab /> : false}
      <Content style={{ height: '100%' }}>{props.children}</Content>
    </div>
  );
}

export default App;
