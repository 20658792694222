import { Modal, Input, Button, message } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.css';
import { getwithdrawapplyapi } from '@/api/rewards/index'


const msg01 = 'Withdrawal amount exceeds available balance'
const msg02 = 'The withdrawal amount cannot be empty'
const msg03 = 'The withdrawal amount cannot be 0'
const msg04 = 'The withdrawal must exceed $50'

function settype(type) {
    if (type == 1) {
        return msg01
    } else if (type == 2) {
        return msg02
    } else if (type == 2) {
        return msg03
    } else if (type == 3) {
        return msg04
    } else {
        return ''
    }
}

const Changemodule = ({ ischangeAccount, hidechangemodule, userinfo, uid, updateuser }) => {

    const [isread, setread] = useState(false)

    const [withdrawnum, setwithdrawnum] = useState('')

    const [errormsg, seterrormsg] = useState(0)

    const [lodding, stebtnlodding] = useState(false)

    const [readbtn, setreadbtn] = useState(false)

    useEffect(() => {
        if (userinfo.availableAmt < 50) {
            setread(true)
            setreadbtn(true)
        }

    })

    const changewithdrawnum = (e) => {

        let value = e.target.value.replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3')
        if (value > userinfo.availableAmt) {
            seterrormsg(1)
            setreadbtn(true)
        } if (value < 50) {
            seterrormsg(3)
            setreadbtn(true)
        } else {
            seterrormsg(0)
            setreadbtn(false)
        }
        setwithdrawnum(value)
    }

    const sendgetwithdrawapplyfun = () => {
        stebtnlodding(true)
        if (withdrawnum == '') {
            seterrormsg(2)
            stebtnlodding(false)
            return
        }
        if (withdrawnum == 0) {
            seterrormsg(3)
            stebtnlodding(false)
            return
        }
        if (withdrawnum > userinfo.availableAmt) {
            stebtnlodding(false)
            seterrormsg(1)
            return
        } else {
            seterrormsg(0)

        }
        let param = {}
        param.amount = withdrawnum
        param.uid = uid
        console.log(param)
        getwithdrawapplyapi(param).then(res => {
            if (res.code == 0) {
                message.success('Submitted successfully');
                handleCancel()
            } else {
                if (res.msg) {
                    message.error(res.msg);
                } else {
                    message.error('Submitted failure');
                }
            }
            stebtnlodding(false)
            updateuser()
            setwithdrawnum('')
        })
    }

    const handleCancel = () => {
        hidechangemodule(false);
    };
    return (
        <Modal title="Withdraw" visible={ischangeAccount} onCancel={handleCancel} centered footer={null} className={'accountDialogmodalcss'} >
            <div className={styles.changeaccount}>
                <div className={styles.changeaccounttx}>PayPal Account: <span className={styles.accountDialogmodalzh}>{userinfo.paypalAccount}</span></div>
                <Input value={withdrawnum} placeholder="Please enter the withdraw amount" className={styles.changeaccountinput} onChange={e => changewithdrawnum(e)} disabled={isread} />
                {errormsg ? <p className={styles.errormsd}>{settype(errormsg)}</p> : <></>}
                {isread ? <p className={styles.errormsd}>You need to reach $50 to withdraw cash. The withdrawal application will be processed within 30 working days.</p> : <></>}
                <p className={styles.changeaccounttxt}>Paypal charging fee: $0.3 + 3.4%-4.4% of withdraw amount<br></br>(According to your Paypal account terms)</p>
                <Button className={styles.changeaccountbtn} onClick={sendgetwithdrawapplyfun} loading={lodding} disabled={readbtn}>Withdraw</Button>
            </div>
        </Modal>
    )
}

export default Changemodule