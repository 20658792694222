import styles from './index.module.css';
import { Divider } from 'antd';
import { getNoticeDetail } from '@/api/home/home';
import { useEffect, useState } from 'react';
import CommonMainLayout from '@/components/CommonMainLayout';
import { useParams } from 'react-router-dom'
const NewsMain = () => {
    const { id } = useParams();
    const [detail, setDetail] = useState({ content: '', title: '', createTime: '' })
    const getDetail = () => {
        getNoticeDetail(id).then(res => {
            if (res.code == 0) {
                setDetail(res.data)
            }
        })
    }
    useEffect(() => {
        // getDetail()
    }, [0])
    const handleCopy = () => {
        return false
    }
    const element = (<div className={styles.news_main} onCopy={handleCopy}>
        <CommonMainLayout>
            <div className={`${styles.main}`}>
                <div className={styles.title}>{detail.title}</div>
                <div className={styles.time}>{detail.createTime}</div>
                <Divider />
                <div dangerouslySetInnerHTML={{ __html: detail.content }}></div>
            </div>
        </CommonMainLayout>
    </div>)
    return element
}

export default NewsMain