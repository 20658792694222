
import styles from './index.module.css';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { Popover, Button, Modal } from 'antd';
import { HomeFilled, QuestionCircleFilled, FileTextFilled, EditFilled, CreditCardFilled } from '@ant-design/icons';
import { getUserInfo } from '@/api/user/user';
import useCounterModel from '@/model/user';
import { dealToken } from '@/plugins/utils';
import Mask from '@/components/Mask';
import HeadPic from '@/assets/images/head.png';
import Feedback from '@/components/Feedback';
import CommonMainLayout from '@/components/CommonMainLayout'
import { NoticeBar } from 'react-vant'
import { VolumeO } from '@react-vant/icons';
import SubscriptionIcon from '@/assets/images/SubscriptionIcon.png';
import feedbackIcon from '@/assets/images/feedback.svg';

import FeedbackMain from '@/components/FeedbackMainModule'


// icon
import rewardsicon from '@/assets/images/rewards.gif'
const HeadTab = (props) => {
    const { tabList, handleClick } = props;

    const [open, setOpen] = useState(false);
    const handleOk = () => {
        setOpen(true)
    }

    const CloseModal = () => {
        setOpen(false)
    }

    const element = (<div className={styles.head_tab}>
        <div className={`common_main ${styles.head_tab_main}`}>
            {tabList.map(item => item.type == 'link' ?
                <a href={item.link} key={item.title} className={styles.tab_link}>{item.icon}<span className={styles.word}>{item.title}</span></a> :
                <a href='javascript:;' onClick={handleClick} key={item.title} className={styles.tab_link}>{item.icon}<span className={styles.word}>{item.title}</span></a>)}
            {/* <div className={styles.tab_link} onClick={handleOk}><img src={feedbackIcon} className={styles.imgicon}></img><span className={styles.word}>Feedback</span></div> */}
        </div>
        <Modal width={640} title="Submit Feedback" visible={open} centered footer={null} onOk={handleOk} onCancel={CloseModal}>
            <FeedbackMain setIsModalVisible={setOpen}/>
        </Modal>
    </div>)
    return element
}

const SubscriptionIconDom = () => {
    return (
        <img src={SubscriptionIcon} className={styles.imgicon}></img>
    )
}


const GetRewardsico = () => {
    return (
        <img src={rewardsicon} className={styles.imgicon}></img>
    )
}

const Head = () => {
    const { userInfo, setUser, loginFail, setLogin, setTokenFun } = useCounterModel();
    const [loading, setLoading] = useState(false);
    const [overdueday, setoverdueday] = useState(false)
    let uid = dealToken();
    const feedbackRef = useRef()


    const getUserInfoFun = () => {
        if (uid) {
            setTokenFun(uid)
            setLoading(true)
            getUserInfo({ uid: uid }).then(res => {
                setLoading(false)
                if (res.code == 0) {
                    setUser(res.data)
                    setLogin(false)
                    getDistanceSpecifiedTime(res.data.lostActiveTime)
                } else {
                    setLogin(true)
                }
            })
        } else {
            // 报错，未找到用户信息
            setLogin(true)
        }
    }

    const getDistanceSpecifiedTime = (dateTime) => {
        if (!dateTime) {
            setoverdueday(true)
            return
        }
        let date = dateTime.split('-').join('/') + " 00:00:00"

        // 指定日期和时间
        var EndTime = new Date(date);
        // 当前系统时间
        var NowTime = new Date();
        var t = EndTime.getTime() - NowTime.getTime();
        var d = Math.floor(t / 1000 / 60 / 60 / 24);
        if (d <= 7) {
            setoverdueday(true)
            return
        }
    }
    const headPic = () => {
        // return userInfo.icon ? `${userInfo.icon}?x-oss-process=image/resize,h_40` : HeadPic
        return HeadPic
    }
    const handleClick = () => {
        feedbackRef.current.openModal()
    }
    useEffect(() => {
        getUserInfoFun()
    }, [0])
    const tabList = [
        { icon: <HomeFilled />, title: 'Home', link: '/?uid=' + uid, type: 'link' },
        { icon: <FileTextFilled />, title: 'Order Record', link: '/order-record?uid=' + uid, type: 'link' },
        { icon: <SubscriptionIconDom />, title: 'Subscription', link: '/Subscription?uid=' + uid, type: 'link' },
        { icon: <QuestionCircleFilled />, title: 'FAQ', link: '/faq?uid=' + uid, type: 'link' },
        { icon: <GetRewardsico />, title: 'Get Rewards', link: '/getrewards?uid=' + uid, type: 'link' },
    ]
    const element = (
        <>
            {loading ? <Mask /> : false}
            <div className={`${styles.head}`}>
                <CommonMainLayout>
                    <div className={`${styles.head_main} clearfix`}>
                        <div className='fl'>
                            <Popover placement="bottomLeft" content={<><a href={'/user-info?uid=' + uid} className={styles.link}>User Info</a><a href={'/change-password?uid=' + uid} className={styles.link}>Change Password</a></>} trigger="hover">
                                <div className='clearfix'>
                                    <a href={'/user-info?uid=' + uid} className='fl'><Avatar shape="square" size="large" src={headPic()} /></a>
                                    <div className={`${styles.user} fl`}>
                                        <div className={`${styles.name} fl`}>{userInfo.nickName}</div>
                                        {/* <div className={`${styles.version} size14`}>{userInfo.loginName}{loginFail}</div> */}
                                    </div>
                                </div>
                            </Popover>
                        </div>
                        {
                            userInfo.lostActiveTime ? <div className={`${styles.expair} fr ${overdueday ? styles.overduedayclass : ''}`}>ExpiryDate {userInfo.lostActiveTime}</div> : false
                        }
                    </div>
                </CommonMainLayout>
                <CommonMainLayout>
                    <HeadTab tabList={tabList} handleClick={handleClick} />
                    <Feedback ref={feedbackRef} userInfo={userInfo} />
                </CommonMainLayout>
                {/* <CommonMainLayout>
                    <div className='notbarbox'>
                        <NoticeBar
                            delay={0.5}
                            leftIcon={<div className='leftnoticebarico'><VolumeO className='leftnoticebaricoimg' />Update Notice:</div>}
                            text=' From 10:00 to 11:30 on Aug 24 (UTC+8), there will be an update to the server maintenance that will suspend all software functions. Sorry for any inconvenience this may have caused.'
                        />
                    </div>
                </CommonMainLayout> */}


            </div>
        </>
    )
    return element
}
export default Head