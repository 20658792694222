import request, { BaseUrl } from '@/api/request'
const ProductListApi = `${BaseUrl}/soft/web/products`;        // 获取产品列表
const NoticeListApi = `${BaseUrl}/soft/web/notices`;        // 获取公告列表
const NoticeDetailApi = `${BaseUrl}/soft/web/notice-detail/`;        // 获取公告详情
const PayInfoApi = `${BaseUrl}/soft/web/fee`;        // 获取支付信息
const CreateOrderApi = `${BaseUrl}/soft/web/order-create`;        // 创建订单
const PayCodeApi = `${BaseUrl}/soft/web/pay-code`;        // 激活码购买
const PayTypeApi = `${BaseUrl}/soft/web/payment-types`;        // 获取支付方式
const ChangeLogApi = `${BaseUrl}/web/getUpdateMessage`;         // 获取改变日志详情
const valitycapture = `${BaseUrl}/pay/stripe/capture`;  //支付页面成功后返回更新订单状态

const supplierUrl = `${BaseUrl}/soft/web/supplierUrl`; //记录供应商点击量

// 获取产品列表
export const getProducts = () => {
    return request("get", ProductListApi)
}

// 获取公告列表
export const getNoticeList = () => {
    return request("get", NoticeListApi)
}

/**
 * 获取公告详情
 * @param {*} id id
 * @returns 
 */
export const getNoticeDetail = (id) => {
    return request("get", `${NoticeDetailApi}${id}`)
}

/**
 * 获取Change log
 * @param {*} 
 * @returns 
 */
export const getChangelog = () => {
    return request("get", `${ChangeLogApi}`)
}


/**
 * 获取支付信息
 * @param {*} params userId productId
 * @returns 
 */
export const payInfo = (params) => {
    return request("post", PayInfoApi, params, 'form', { "Content-Type": "multipart/form-data" })
}


/**
 * 创建订单
 * @param {*} params userId orderId productId paymentMethodId
 * @returns 
 */
export const createOrderInfo = (params) => {
    return request("post", CreateOrderApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

//支付页面成功后返回更新订单状态
export const valitycapturefun = (params) => {
    return request("post", valitycapture, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 激活码购买
 * @param {*} params code uid
 * @returns 
 */
export const payCode = (params) => {
    return request("post", PayCodeApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 获取支付方式
 * @param {*} params 
 * @returns 
 */
export const payType = (params) => {
    return request("post", PayTypeApi, params, 'form', { "Content-Type": "multipart/form-data" })
}


export const supplierUrlApi = (params) => {
    return request("post", supplierUrl, params)
}