import request, { BaseUrl } from '@/api/request'
const faqListApi = `${BaseUrl}/soft/web/faqList`;        // 问答
const noticeListApi = `${BaseUrl}/soft/web/noticeList`;        // 软件更新日志 


/**
 * 获取问答列表
 * @param {*} params type
 * @returns 
 */
export const getFAQ = (params) => {
    return request("get", faqListApi, params)
}

/**
 * 获取软件更新日志
 * @param {*} params title
 * @returns 
 */
export const getnoticeList = (params) => {
    return request("get", noticeListApi, params)
}


