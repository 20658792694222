import styles from './index.module.css';
import CommonMainLayout from '@/components/CommonMainLayout';
import { Link } from 'react-router-dom';
import { dealToken } from '@/plugins/utils';
import { Modal, Input, Button, message } from 'antd';
import { useEffect, useState } from 'react'

import { getUserInfoapi } from '@/api/rewards/index'
import Changemodule from './ChangeModal'

import Withdraw from './withdraw/index'

// const url = 'https://www.refoxteam.com/register?key='
const url = 'https://www.refoxteam.com/promotion?utm_campaign='
const share = '&utm_source=ReferralProgram' //google监测
// 邀请链接复制
const Sharelink = ({ userinfo }) => {
    const copyAction = (str) => {
        const createInput = document.createElement('input');
        createInput.value = str;
        document.body.appendChild(createInput);
        createInput.select();
        document.execCommand('Copy'); // document执行复制操作
        createInput.remove();
        message.success('Copy Successfully', 5)
    }
    const copytxt = (val) => {
        copyAction(val)

    }
    return (
        <div className={styles.Sharelinkbox}>
            <div className={styles.Sharelinkhead}>Invited Friends & Get Rewards Together</div>
            <p className={styles.Sharelinkbody}>Share your link to invite friends and earn up to lifetime free plan of REFOX Bitmap with 20% cash back!</p>
            <div className={styles.sharelinkcopy}>
                <div className={styles.sharelink}>
                    Copy this shareable link: <span className={styles.sharelinkhref}>{url}{userinfo.friendToken}{share}</span>
                </div>
                <div className={styles.sharelinkcopybtn} onClick={() => copytxt(url + userinfo.friendToken + share)}>
                    Copy
                </div>
            </div>
        </div>
    )
}

const Shareinfo = ({ showchangemodule, userinfo }) => {
    let uid = dealToken();
    return (
        <div className={`clearfix ${styles.shareinfo}`}>
            <div className={`${styles.shareinfoitem} ${styles.borderright}`}>
                <Link to={`/promotedetails?uid=` + uid + '&select=1'}>
                    <div className={styles.Friendsnum}>
                        {userinfo.invitedCount}
                    </div>
                    <div className={styles.Friendstxt}>Friends Invited</div>
                </Link>
            </div>
            <div className={`${styles.shareinfoitem} ${styles.borderright}`}>
                <Link to={`/promotedetails?uid=` + uid}>
                    <div className={styles.Friendsnum}>
                        {userinfo.earnDayCount}<span className={styles.Friendsday}>/Days</span>
                    </div>
                    <div className={styles.Friendstxt}>Free Plan Earned</div>
                </Link>
            </div>
            <div className={styles.shareinfoitem}>
                <Link to={`/promotedetails?uid=` + uid + '&select=2'}>
                    <div className={styles.cashprice}>
                        ${userinfo.availableAmt}
                    </div>
                    <div className={styles.Friendstxt}>Cash Back</div>
                </Link>
                <Button className={styles.sharetag2withdraw} onClick={showchangemodule}>Withdraw</Button>

            </div>
        </div>
    )
}




const Rewards = () => {
    // 修改账户弹窗
    const [modulestate, setmodulestate] = useState(false)

    // 提现弹窗
    const [withdrawmodulestate, setwithdrawmodulestate] = useState(false)
    const [userinfo, setuserinfo] = useState({})

    const showchangewithdrawmodule = () => {
        setwithdrawmodulestate(true)
    }
    const hidechangewithdrawmodule = () => {
        setwithdrawmodulestate(false)
    }
    // 修改账户弹窗显示隐藏
    const showchangemodule = () => {
        setmodulestate(true)
    }
    const hidechangemodule = () => {
        setmodulestate(false)
    }

    const handleOpenNewWindow = () => {
        window.open('https://www.refoxteam.com/bitmap/refer-a-friend/', 'refox team', "menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1")
    }

    const clickwithdrawal = () => {
        if (userinfo.paypalAccount) {
            showchangewithdrawmodule()
        } else {
            showchangemodule()
        }
    }

    let uid = dealToken();

    const updateduserinfo = () => {
        getUserInfoapi(uid).then(res => {
            if (res.code == 0) {
                setuserinfo(res.data)
            }

        })
    }

    useEffect(() => {
        if (uid) {
            updateduserinfo()
        }

    }, [0])

    return (
        <CommonMainLayout>
            {uid ? (<><div className={styles.Getrewards_main}>
                <Sharelink userinfo={userinfo} />
                <div className={`clearfix ${styles.sharetag}`}>
                    <div className={styles.sharetag1}>
                        <h4 className={styles.sharetag1head}>How it works</h4>
                        <ul className={styles.sharetag1step}>
                            <li className={styles.sharetag1stepli}><span className={styles.listnum}>1</span>Share your link to invite a friend </li>
                            <li className={styles.sharetag1stepli}><span className={styles.listnum}>2</span>Your friend subscribes to any plan </li>
                            <li className={styles.sharetag1stepli}><span className={styles.listnum}>3</span>You and your friend get free plan days!</li>
                            <li className={styles.sharetag1stepli}><span className={styles.listnum}>4</span>You can also get 20% cashback!</li>
                        </ul>
                        <div onClick={handleOpenNewWindow} className={styles.sharetag1learn}>Learn More {'>>'}</div>
                    </div>
                    <div className={styles.sharetag2}>
                        <Shareinfo showchangemodule={clickwithdrawal} userinfo={userinfo} />
                    </div>
                </div>
            </div>
                <Changemodule ischangeAccount={modulestate} hidechangemodule={hidechangemodule} uid={uid} getuserinfo={updateduserinfo} />
                <Withdraw ischangeAccount={withdrawmodulestate} hidechangemodule={hidechangewithdrawmodule} userinfo={userinfo} uid={uid} updateuser={updateduserinfo} />
            </>) : <></>}
        </CommonMainLayout>

    )
}

export default Rewards