import CryptoJS from "crypto-js";

//十六位十六进制数作为密钥
const key = CryptoJS.enc.Utf8.parse("Rewa@Refox#123.*");
//解密方法
export const Decrypt = (word) => {
    //如果报错了，请先检查前后端密钥是否一致
    let decrypt = CryptoJS.AES.decrypt(word.toString(), key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr;
}

export const dealToken = () => {
    const location = window.location;
    let uid = false
    if (location.search.includes('uid')) {
        uid = location.search.split('?uid=')[1]
        if (uid.indexOf('&') >= 0) {
            uid = uid.split('&')[0]
        }
        window.sessionStorage.setItem('uid', JSON.stringify(uid))
    } else {
        uid = window.sessionStorage.getItem('uid') ? JSON.parse(window.sessionStorage.getItem('uid')) : false
    }
    return uid
}

// 封装工具类
class Utils {
    constructor() {
        this.KEY = CryptoJS.enc.Utf8.parse('HN2FaAH|CM>CJM,F'); // 十六位十六进制数作为密钥
        this.IV = CryptoJS.enc.Utf8.parse('0000000000000000'); // 十六位十六进制数作为密钥
    }
    // 解密
    decrypt(str) {
        let encryptedHexStr = CryptoJS.enc.Hex.parse(str);
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        let decrypt = CryptoJS.AES.decrypt(srcs, this.KEY, {
            iv: this.IV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }
    // 加密
    encrypt(str) {
        let srcs = CryptoJS.enc.Utf8.parse(str);
        let encrypted = CryptoJS.AES.encrypt(srcs, this.KEY, {
            iv: this.IV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.ciphertext.toString().toUpperCase();
    }
}
export default new Utils();