import CommonImage from '@/components/CommonImage';
import styles from './index.module.css';
import CardBg from '@/assets/images/bg_220317.png';
import Logo from '@/assets/images/refox_logo_220317.png';
import Refox from '@/assets/images/refox_text_220317.png';
const RegLayout = (props) => {
    const element = (<div className={`${styles.register_main} register`}>
        <div className={`${styles.main}`}>
            <div className={`fl ${styles.main_left}`}>
                <CommonImage url={CardBg} width={320} />
                <div className={styles.left_mask}>
                    <div className={styles.mask_top}>
                        <CommonImage
                            url={Logo}
                            width={87}
                        />
                        <div className={`${styles.wel}`}>Welcome</div>
                    </div>
                    <div className={styles.mask_bottom}>
                        <CommonImage
                            url={Refox}
                            width={100}
                        />
                        <div className={styles.bottom_version}>
                            REFOX Version 1.0 Beta
                        </div>
                    </div>
                </div>
            </div>
            <div className={`fl ${styles.main_right} `}>{props.children}</div>
        </div>
    </div>);
    return element
}
export default RegLayout