import request, { BaseUrl } from '@/api/request'

const UserInfoApi = `${BaseUrl}/soft/web/user-info`;  //获取用户信息
const UserOrderListApi = `${BaseUrl}/soft/web/user-orders`;  //用户订单列表
const CancelOrderApi = `${BaseUrl}/pay/order/cancel`;  //取消订单
const ContinueOrderApi = `${BaseUrl}/pay/order/continue`;  //继续订单
const UserUpdateApi = `${BaseUrl}/soft/web/user-update`; // 修改用户信息
const UploadApi = `${BaseUrl}/soft/web/upload`; // 上传头像
const CheckPwdApi = `${BaseUrl}/soft/web/checkPassword`; // 校验密码
const ChangePasswordApi = `${BaseUrl}/soft/web/changePassword`; // 修改密码
const FeedbackApi = `${BaseUrl}/soft/web/feedback`; // 用户意见反馈
const questionnaireStatus = `${BaseUrl}/soft/web/checkPop`; // 请求用户是否填写过调查问卷0已填写 1过期后未填 2续费后未填
const questionnaireFrom = `${BaseUrl}/soft/web/useFeedback`; // 提交的调查问卷内容
/**
 * 获取用户信息
 * @param {*} params uid
 * @returns 
 */
export const getUserInfo = (params) => {
    return request("post", UserInfoApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 用户订单列表
 * @param {*} params loginName
 * @returns 
 */
export const getUserOrderList = (params) => {
    return request("post", UserOrderListApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 取消订单
 * @param {*} params id
 * @returns 
 */
export const CancelOrder = (params) => {
    return request("post", CancelOrderApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 继续订单
 * @param {*} params id
 * @returns 
 */
export const ContinueOrder = (params) => {
    return request("post", ContinueOrderApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 用户信息更新
 * @param {*} params id icon nickName
 * @returns 
 */
export const UserUpdateReq = (params) => {
    return request("post", UserUpdateApi, params,)
}

/**
 * 上传图片
 * @param {*} params file
 * @returns 
 */
export const UploadReq = (params) => {
    return request("post", UploadApi, params, 'form', { "Content-Type": "multipart/form-data" })
}


/**
 * 校验密码
 * @param {*} params id pwd
 * @returns 
 */
export const CheckPwd = (params) => {
    return request("post", CheckPwdApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 修改密码
 * @param {*} params  id old pwd confirmPwd
 * @returns 
 */
export const ChangePassword = (params) => {
    return request("post", ChangePasswordApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 用户意见反馈
 * @param {*} params content image userId
 * @returns 
 */
export const FeedbackReq = (params) => {
    return request("post", FeedbackApi, params)
}

/**
 * 请求用户是否填写过调查问卷0已填写 1续费用户 2未续费用户
 * @param {*} params content image userId
 * @returns 
 */
export const GetQuestionnaireStatus = (params) => {
    return request("get", questionnaireStatus, params)
}

/**
 * 请求用户是否填写过调查问卷0已填写 1续费用户 2未续费用户
 * @param {*} params content image userId
 * @returns 
 */
export const QuestionnaireFrom = (params) => {
    return request("post", questionnaireFrom, params)
}


