/**
 * 网络请求配置
 */
import axios from "axios";

axios.defaults.timeout = 100000;
// axios.defaults.baseURL = "http://47.74.224.108:8080";
// axios.defaults.baseURL = "http://192.168.10.35:8080";

// export const BaseUrl = 'http://192.168.10.231:8080'
// export const BaseUrl = 'http://192.168.2.16:8080'
// export const BaseUrl = 'http://192.168.2.29:8080'
export const BaseUrl = 'https://soft.refoxteam.com'
/**
 * http request 拦截器
 */
// axios.interceptors.request.use(
//     (config) => {
//         config.data = JSON.stringify(config.data);
//         config.headers = {
//             "Content-Type": "application/json",
//         };
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
    (response) => {
        if (response.data.errCode === 2) {
            console.log("过期");
        }
        return response;
    },
    (error) => {
        console.log("请求出错：", error);
    }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        }).then((response) => {
            if (response) {
                try {
                    landing(url, params, response.data);
                    resolve(response.data);
                } catch (err) {
                    throw (err)
                }
            } else {
                reject('Error')
            }
            // console.log(response)
        })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data, type, headers) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: headers ? headers : { "Content-Type": "application/json" }, transformRequest: [function (data) {
                // 对 data 进行任意转换处理
                let bodyFormData = new FormData();
                if (type == 'form') {
                    for (let key in data) {
                        bodyFormData.append(key, data[key])
                    }
                } else {
                    bodyFormData = JSON.stringify(data)
                }
                return bodyFormData;
            }]
        }).then(
            (response) => {
                try {
                    //关闭进度条
                    resolve(response.data);
                } catch (err) {
                    throw (err)
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                msag(err);
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                msag(err);
                reject(err);
            }
        );
    });
}

//统一接口处理，返回数据
export default function (fecth, url, param, type, headers) {
    let _data = "";
    return new Promise((resolve, reject) => {
        switch (fecth) {
            case "get":
                get(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log("get request GET failed.", error);
                        reject(error);
                    });
                break;
            case "post":
                post(url, param, type, headers)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log("get request POST failed.", error);
                        reject(error);
                    });
                break;
            default:
                break;
        }
    });
}

//失败提示
function msag(err) {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                alert(err.response.data.error.details);
                break;
            case 401:
                alert("未授权，请登录");
                break;

            case 403:
                alert("拒绝访问");
                break;

            case 404:
                alert("请求地址出错");
                break;

            case 408:
                alert("请求超时");
                break;

            case 500:
                alert("服务器内部错误");
                break;

            case 501:
                alert("服务未实现");
                break;

            case 502:
                alert("网关错误");
                break;

            case 503:
                alert("服务不可用");
                break;

            case 504:
                alert("网关超时");
                break;

            case 505:
                alert("HTTP版本不受支持");
                break;
            default:
        }
    }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url, params, data) {
    if (data.code === -1) {
    }
}

