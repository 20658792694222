import request, { BaseUrl } from '@/api/request'

const subscriptionDetailsApi = `${BaseUrl}/soft/web/subscriptionDetails?uid=`;        // 获取产品列表
const addTaxInfoApi = `${BaseUrl}/soft/web/addTaxInfo`
const editTaxInfoApi = `${BaseUrl}/soft/web/editTaxInfo`
const selectTaxInfoApi = `${BaseUrl}/soft/web/selectTaxInfo`
const paypalSubscriptionOpApi = `${BaseUrl}/soft/web/paypalSubscriptionOp` //paypal
const stripeSubscriptionOpApi = `${BaseUrl}/soft/web/stripeSubscriptionOp`  //stripe

const downloadInvoiceApi = `${BaseUrl}/soft/web/downloadInvoice`



/**
 * 获取用户订阅信息
 * @param {*} id uid
 * @returns 
 */
export const getSubscriptionDetails = (id) => {
    return request("get", `${subscriptionDetailsApi}${id}`)
}

/**
 * 新增发票信息
 * @param {*} params 
 * @returns 
 */
export const addTaxInfo = (params) => {
    return request("post", addTaxInfoApi, params)
}

/**
 * 编辑发票信息
 * @param {*} params 
 * @returns 
 */
export const editTaxInfo = (params) => {
    return request("post", editTaxInfoApi, params)
}

/**
 * 查询发票信息
 * @param {*} params 
 * @returns 
 */
export const selectTaxInfo = (params) => {
    return request("post", selectTaxInfoApi, params)
}



/**
 * paypal取消订阅
 * @param {*} params 
 * @returns 
 */
export const paypalSubscriptionOp = (params) => {
    return request("post", paypalSubscriptionOpApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * stripe取消订阅
 * @param {*} params 
 * @returns 
 */
export const stripeSubscriptionOp = (params) => {
    return request("post", stripeSubscriptionOpApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 下载发票
 * @param {*} data 订单id
 * @returns 
 */
export const downloadInvoice = (data) => {
    return request("post", downloadInvoiceApi, data)
}