import request, { BaseUrl } from '@/api/request'
const getUserInfo = `${BaseUrl}/member/info`;        // 获取用户信息
const getinvited = `${BaseUrl}/member/friends/invited`;        // 获取朋友推荐列表
const getwithdrawlist = `${BaseUrl}/member/withdraw/list`;  //获取提现记录
const getamountlogs = `${BaseUrl}/member/amount/logs`;  //获取余额使用记录
const updateaccount = `${BaseUrl}/member/account/update`;  //更新PayPal账户
const getwithdrawapply = `${BaseUrl}/member/withdraw/apply`;  //提现申请



/**
 * 获取用户信息
 * @param {*} params title
 * @returns 
 */
export const getUserInfoapi = (params) => {
    return request("post", getUserInfo + '?uid=' + params, {}, 'form', { "Content-Type": "multipart/form-data" })
}



/**
 * 获取朋友推荐列表
 * @param {*} params title
 * @returns 
 */
export const getinvitedapi = (params) => {
    return request("post", getinvited + '?uid=' + params, {}, 'form', { "Content-Type": "multipart/form-data" })
}


/**
 * 获取提现记录
 * @param {*} params title
 * @returns 
 */
export const getwithdrawlistapi = (params) => {
    return request("post", getwithdrawlist + '?uid=' + params, {}, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 获取余额使用记录
 * @param {*} params title
 * @returns 
 */
export const getamountlogsapi = (params) => {
    return request("post", getamountlogs + '?uid=' + params, {}, 'form', { "Content-Type": "multipart/form-data" })
}


/**
 * 更新PayPal账户
 * @param {*} params title
 * @returns 
 */
export const updateaccountapi = (params) => {
    return request("post", updateaccount + '?uid=' + params.uid + '&account=' + params.account, {}, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 提现申请
 * @param {*} params title
 * @returns 
 */
export const getwithdrawapplyapi = (params) => {
    return request("post", getwithdrawapply + '?uid=' + params.uid + '&amount=' + params.amount, {}, 'form', { "Content-Type": "multipart/form-data" })
}




