import styles from './index.module.css'
import { getChangelog } from '@/api/home/home';
import { useState, useEffect } from 'react';
import CommonMainLayout from '@/components/CommonMainLayout';
import { BackTop, Pagination } from 'antd';
import backtop from '@/assets/images/backtop.png';
import { getnoticeList } from '@/api/Faq'


const Dataformat = (times) => {
    return times.split(' ')[0]
}


const Logsitem = (props) => {
    return (
        <div className={styles.Logsitembox}>
            <table>
                <tr>
                    <td valign='top'><div className={styles.Logsitemboxhead}>
                        {Dataformat(props.itemdata.updateTime)}
                    </div></td>
                    <td><div className={styles.Logsitemboxcontent}>{props.itemdata.content}</div></td>
                </tr>
            </table>
        </div>
    )
}

const FaqMain = () => {
    const [detail, setDetail] = useState({});
    const [noticeList, setnoticeList] = useState([])
    const [zpageNum, setpageNum] = useState(1)
    const [zpageSize, setpageSize] = useState(20)
    const [pagetotal, settotal] = useState(0)

    const getnoticeListinfo = (params) => {
        let pagedate
        if (!params) {
            pagedate = {
                pageNum: zpageNum,
                pageSize: zpageSize
            }
        } else {
            pagedate = params
        }
        getnoticeList(pagedate).then(res => {
            setnoticeList(res.rows)
            settotal(res.total)
        })
    }

    useEffect(() => {
        getnoticeListinfo()
    }, [0])

    const getDetail = () => {
        getChangelog().then(res => {
            if (res.code == 0) {
                setDetail(res.data)
            }
        })
    }

    const changepagedata = (current, pageSize) => {
        let pagedate = {
            pageNum: current,
            pageSize: pageSize,
        };
        getnoticeListinfo(pagedate);
        setpageNum(current);
        setpageSize(pageSize);
    }

    useEffect(() => {
        // getNoticeListFun();
        getDetail()
    }, [0])

    const element = (<CommonMainLayout><div className={styles.logs_main}>
        {
            noticeList.map(res => {
                return (<div key={res.id}><Logsitem itemdata={res} ></Logsitem></div>)
            })
        }
        <div className='Pagination-center'>
            <Pagination current={zpageNum} pageSize={zpageSize} total={pagetotal} center onChange={changepagedata} />
        </div>
    </div>
        <BackTop>
            <div>
                <img src={backtop}></img>
            </div>
        </BackTop>
    </CommonMainLayout>)
    return element
}
export default FaqMain