import { Button, Modal, Radio, Space, Checkbox, Tooltip } from 'antd';
import { useState, useEffect, forwardedRef } from 'react';
import { Link } from 'react-router-dom';
import CommonImage from '@/components/CommonImage'
import styles from './index.module.css'
import { dealToken } from '@/plugins/utils';
import iconInfo from '@/assets/images/info.png'
const PayDialog = (props) => {
    let uid = dealToken();
    const { payFlag, setPayFlag, payDetail, handleToPay, payLoading, setPayLoading, userinfo, setpaypalkey } = props;
    const { fees, paymentMethod, product } = payDetail
    const [currentPay, setCurrentPay] = useState({});
    const [payways, setPayways] = useState();
    const [btnLoading, setBtnLoading] = useState(false)
    const handleOk = () => { setPayFlag(false) }
    const [ischeckout, setcheckout] = useState(false)
    // 隐藏支付选项
    const [hidepaypal, sethidepaypal] = useState(false)

    const [color, setcolor] = useState('#fff')

    const handleCancel = () => {
        setPayFlag(false)
        setpaypalkey(0)

    }
    const onChange = (e) => {
        setPayways(e.target.value);
        let paway = null
        for (let i = 0; i < paymentMethod.length; i++) {
            if (paymentMethod[i].id == e.target.value) {
                paway = paymentMethod[i];
                break;
            }
        }
        setCurrentPay(paway)
    };
    // 选择是否使用余额支付
    const changecheckout = (e) => {
        let value = e.target.checked
        setcheckout(value)
        if (value) {
            // 判断支付金额是否大于可用余额
            if (detalShowPrice(4) > userinfo.availableAmt) {

            } else {
                sethidepaypal(true)
            }
        } else {
            sethidepaypal(false)
        }
    }
    const detalShowPrice = (type) => {
        for (let key in fees) {
            if (key == currentPay.id) {
                switch (type) {
                    case 0:
                        return fees[key]?.symbol
                    case 1:
                        return fees[key]?.totalPrice
                    case 2:
                        return fees[key]?.currency
                    case 3:
                        return fees[key]?.handling
                    case 4:
                        return fees[key]?.itemPrice
                }
            }
        }
    }


    const returnprice = () => {
        if (!ischeckout) {
            return detalShowPrice(1)
        }
        if (detalShowPrice(4) > userinfo.availableAmt) {
            return payDetail.balanceFees[payways].totalPrice
        }
    }

    const returnFeeprice = () => {
        if (!ischeckout) {
            return detalShowPrice(3)
        }
        if (detalShowPrice(4) > userinfo.availableAmt) {
            return payDetail.balanceFees[payways].handling
        }
    }
    // 去支付，生成支付订单
    const handlePayment = () => {
        if (payDetail.status == 1) {
            return false
        }
        let currentFee = null;
        for (let key in fees) {
            if (key == currentPay.id) {
                currentFee = fees[key]
            }
        }

        let params = { productId: product.id, paymentMethodId: currentPay.id }
        if (ischeckout) {
            params.isDeduct = 1
            if (hidepaypal) {
                params.paymentMethodId = 4
            }
        }


        setPayLoading(true)
        // const { currency, handling, itemPrice, totalPrice } = currentFee;
        // let params = { productId: product.id, currencyCode: currency, handling: handling, itemPrice: itemPrice, payTypeName: currentPay.name, productName: product.name, totalPrice: totalPrice }
        handleToPay(params)
    }

    const closeBtnLoading = () => {
        setBtnLoading(false)
    }
    const handleOpenNewWindow = () => {
        window.open('https://www.refoxteam.com/bitmap/Service-Terms/', 'refox team', "menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1")
    }

    const handleOpenSub = () => {
        window.open('https://www.refoxteam.com/bitmap/Subscription-and-Auto-Renewal-Terms', 'refox team', "menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1")
    }
    useEffect(() => {
        if (payDetail.paymentMethod && paymentMethod[0]) {
            setPayways(paymentMethod[0].id);
            setCurrentPay(paymentMethod[0]);
        }
    }, [payDetail, payFlag])
    const element = (<Modal width={640} title="Select Payment Method" className={'PayDialogmodalcss'} visible={payFlag} centered footer={null} onOk={handleOk} onCancel={handleCancel}>
        {payDetail.paymentMethod ? <>
            <div className={styles.pay_item}>
                <span className={styles.label}>Product:</span><span>REFOX Bitmap Subscription - {product && product.name}&nbsp;{product && product.subName ? `/ ${product.subName} ` : false} Plan</span>
            </div>
            <div className={styles.pay_item}>
                <span className={styles.label}>Price:</span>
                <span>{detalShowPrice(0)}<span className={styles.price}>{detalShowPrice(4)}</span> {detalShowPrice(2)}</span>
            </div>
            {
                !hidepaypal ? (<div className={styles.pay_item}>
                    <span className={styles.label}>Transaction Fee:</span>
                    <span>{detalShowPrice(0)}<span className={styles.price}>{returnFeeprice()}</span> {detalShowPrice(2)}</span>
                </div>) : <></>
            }
            {ischeckout ? (<div className={styles.pay_item}>
                <span className={styles.label}>Discount:</span>
                <span>-{detalShowPrice(0)}<span>{userinfo.availableAmt > detalShowPrice(4) ? detalShowPrice(4) : userinfo.availableAmt}</span> {detalShowPrice(2)}</span>
            </div>) : <></>
            }
            <div className={styles.pay_item}>
                <span className={styles.label}>Grand Total:</span>
                <span>{detalShowPrice(0)}<span className={styles.price}>{hidepaypal ? '0' : returnprice()}</span> {detalShowPrice(2)}</span>
            </div>
            {payDetail.firstOrder ? (<div className={styles.pay_item}>
                <span className={styles.label}>Bonus:</span>
                <span>Get +<span className={styles.price}>{payDetail.product.id == "1" ? "7" : "30"}</span> Days Free (Invited By Friend) </span>
            </div>) : <></>
            }
            <div className={styles.pay_OrderInfoItem} >
                Automatically renew, cancel at any time.  <Tooltip title={<div className={styles.pay_Tips}>The paid subscriptions is billed monthly (plus transaction fee). Only available for 1/3 PC at time.  <Link to={`/Subscription?uid=` + uid}>Cancel</Link> your subscription at
                    any time.</div>} color={color} key={color}>
                    <Button className={styles.pay_InfoBtn}>   <img height={'100%'} src={iconInfo} /></Button>
                </Tooltip>
            </div>
            <div className={`${styles.pay_item} clearfix`} >
                {/* <span className={`${styles.label} fl`}>Payment Method:</span> */}
                {
                    !hidepaypal ? (<div className={`${styles.pay_wrap} fl`}>
                        <Radio.Group onChange={onChange} value={payways}>
                            {paymentMethod && paymentMethod.map(pay =>
                                <Radio value={pay.id} key={pay.id} className={styles.radio_wrap}>
                                    <div className={styles.logo}><CommonImage url={pay.logo} height={'100%'} /></div>
                                </Radio >)}
                        </Radio.Group>
                    </div>) : <></>
                }

            </div>
            {userinfo.availableAmt ? (<div className={styles.yongjingbox}>
                <Checkbox value={ischeckout} onChange={(e) => { changecheckout(e) }} disabled={userinfo.availableAmt ? false : true} ><span className={styles.yongjingtxt}>Use the entire cash  </span><span className={styles.yongjing}>(Available Credit: ${userinfo.availableAmt})</span></Checkbox>
            </div>) : <></>}
        </> : <>
            {payDetail.status == 1 ?
                (<p className={styles.notepaytitle}>Please contact <span className={styles.notepaytxt}>bitmap@refoxteam.com</span> for payment issues.</p>) : <></>}
        </>}

        <div className={styles.modal_btn_wrap}>
            <Button type="primary" size='large' block className={styles.modal_btn} disabled={payDetail.status != 0} shape="round" loading={payLoading} onClick={handlePayment}>Confirm Payment</Button>
            <Button size='large' block className={styles.Cancelbtn} onClick={handleCancel}>Cancel</Button>
            <div className={styles.termsbox}>By clicking ‘Confirm Payment’, you agree to our <span onClick={handleOpenNewWindow} className={styles.termsboxlink}>Service Terms</span> and <span onClick={handleOpenSub} className={styles.termsboxlink}>Subscription and Cancellation Terms</span>. Cancel the auto-renewal before your current plan ends and you won't be charged.</div>
        </div>

    </Modal>)
    return element
}
export default PayDialog