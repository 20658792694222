import SubscriptionMain from '@/components/SubscriptionMain'
import { useEffect } from 'react'
import { getSubscriptionDetails } from '@/api/Subscription'
import { dealToken } from '@/plugins/utils';
import { getUserInfoapi } from '@/api/rewards/index'
import { useState } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { selectTaxInfo } from '@/api/Subscription'
import NoData from '@/assets/images/NoData2.svg'
import CommonMainLayout from '@/components/CommonMainLayout';
const Subscription = () => {
    let uid = dealToken();
    const histiry = useHistory()

    const [pageStatus, setpageStatus] = useState('')
    const [TaxInfo, setTaxInfo] = useState()
    const [loadingTaxInfoStatus, setloadingTaxInfoStatus] = useState(true)
    const [UserInfo, setUserInfo] = useState()
    /**
     * 获取发票抬头
     * @param {*} id uid
     */
    const getSelectTaxInfo = (id) => {
        selectTaxInfo({ uid: id }).then((res) => {
            setloadingTaxInfoStatus(true)
            if (res.code == 0 && res.data.id) {
                setTaxInfo(res.data)
            }
        })
    }

    const initTaxInfo = () => {
        if (uid) {
            getSelectTaxInfo(uid)
        }
    }

    const GetUserInfoFun = (uid) => {
        getUserInfoapi(uid).then(res => {
            if (res.code == 0) {
                setUserInfo(res.data)
            }
        })
    }

    const initPageData = () => {
        return new Promise((resolve, reject) => {
            getSubscriptionDetails(uid).then(res => {
                if (res.code == 0) {
                    setpageStatus(res.data)
                    resolve(res.data)
                }
            })
        })
    }


    useEffect(() => {
        if (uid) {
            initPageData()
        }
        if (uid && loadingTaxInfoStatus) {
            setloadingTaxInfoStatus(false)
            getSelectTaxInfo(uid)
            GetUserInfoFun(uid)
        }
    }, [0])
    if (pageStatus) {
        if (pageStatus && pageStatus.status) {
            return <SubscriptionMain PayData={pageStatus} TaxInfo={TaxInfo} initTaxInfo={initTaxInfo} UserInfo={UserInfo} initPageData={initPageData} />
        } else {
            return <CommonMainLayout><div className='defaultModule'>
                <img className='defaultNotDataImg' src={NoData} />
                <p>Subscribe to Unlock Full Features</p>
            </div></CommonMainLayout>
        }

    } else {
        return <div className="common_main SubscriptionPage"> <Spin size={'large'} /> </div>
    }
}
export default Subscription
