import request, { BaseUrl } from '@/api/request'
const CaseCategoryApi = `${BaseUrl}/soft/web/case-category`;        // 获取案例分类列表
const CaseDetailApi = `${BaseUrl}/soft/web/case-detail`;        // 获取案例详情 


/**
 * 获取案例分类列表
 * @param {*} params type
 * @returns 
 */
export const getCaseCategory = (params) => {
    return request("get", CaseCategoryApi, params)
}

/**
 * 获取案例分类列表
 * @param {*} params title
 * @returns 
 */
export const getCaseDetail = (params) => {
    return request("post", CaseDetailApi, params, 'form', { "Content-Type": "multipart/form-data" })
}


