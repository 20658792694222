import HomeMain from '@/components/Home/HomeMain';
import HomeFooter from '@/components/Home/HomeFooter'
import Questionnaire from '@/components/Home/Questionnaire'
import { useEffect } from 'react';
import { dealToken } from '@/plugins/utils';
import { GetQuestionnaireStatus } from '@/api/user/user'
import { useState } from 'react';
const Home = () => {
    let uid = dealToken();
    const [status, settstus] = useState('')
    useEffect(() => {
        let statusType = window.sessionStorage.getItem('isSendQuestionnaire')
        if (!statusType) {
            GetQuestionnaireStatus({ uid: uid }).then(res => {
                if (res.code === 0) {
                    window.sessionStorage.setItem('isSendQuestionnaire', 1)
                    settstus(res.data)
                }
            })
        }
    }, [0])
    const element = (<>
        <HomeMain />
        {status != 0 ? <Questionnaire status={true} uid={uid} type={status} /> : <></>}
        <HomeFooter />
    </>)
    return element
}
export default Home