import useCounterModel from '@/model/user';
import styles from './index.module.css';
import { Form, Input, Button, Row, Col } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dealToken } from '@/plugins/utils';
import CommonMainLayout from '@/components/CommonMainLayout';

const SwitchAccountMain = () => {
    const history = useHistory()
    const { userInfo } = useCounterModel();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    let uid = dealToken();
    const [emailStatus, setEmailStatus] = useState(0); // 0 - 未验证 1 - 验证通过 2 - 验证未通过 3 - 正在验证中
    const [emailFeedback, setEmailFeedback] = useState(false);
    const onValuesChange = () => {
        console.log('我被触发了')
    }
    const onFinish = (values) => {
        let params = {
            id: userInfo.id,
            old: values.currentPassword,
            pwd: values.password,
            confirmPwd: values.confirmPassword
        }
        // setLoading(true)
        // ChangePassword(params).then(res => {
        //     setLoading(false)
        //     if (res.code == 0) {
        //         message.success('Password reset complete');
        //         history.push(`/?uid=${uid}`)
        //     } else {
        //         message.error('Password modification failed, please try again later')
        //     }
        // })
    };
    const element = (<div className={styles.change_password}>
        <CommonMainLayout>
            <div className={`common_main ${styles.main}`}>
                <Row>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        {userInfo.nickName && <Form
                            name="basic"
                            labelAlign="left"
                            form={form}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={onFinish}
                            onValuesChange={onValuesChange}
                            autoComplete="off"
                            className={styles.form}
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                validateFirst={true}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{ required: true, message: 'Please enter your username' }]}
                            >
                                <Input placeholder="Username" />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                validateFirst
                                name="password"
                                maxLength={50}
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password placeholder='Password' visibilityToggle={false} />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 6, span: 6 }}>
                                <Button type="primary" htmlType="submit" loading={loading} block size='large'>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>}
                    </Col>
                </Row>

            </div>
        </CommonMainLayout>

    </div>);
    return element
}
export default SwitchAccountMain