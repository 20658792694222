import styles from './index.module.css';
import CaseTree from '@/components/RefoxCaseMain/CaseTree';
import CaseDesc from '@/components/RefoxCaseMain/CaseDesc';
import { useState } from 'react';
const RefoxCaseMain = () => {
    const [detail, setDetail] = useState('');
    const element = (<div className={styles.refox_case_main}>
        <div className={`${styles.main} common_main clearfix`}>
            <div className={`fl ${styles.case_nav}`}>
                <CaseTree className={`fl ${styles.case_nav}`} setDetail={setDetail} />
            </div>
            <CaseDesc className={'fl'} detail={detail} />
        </div>
    </div>);
    return element
}
export default RefoxCaseMain

