import styles from './index.module.css';
import useCounterModel from '@/model/user';
import { Form, Input, Button, message, Row, Col } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dealToken } from '@/plugins/utils';
import { CheckPwd, ChangePassword } from '@/api/user/user';
import CommonMainLayout from '@/components/CommonMainLayout';
const UserUpdate = (props) => {
    const history = useHistory()
    const { userInfo } = useCounterModel();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    let uid = dealToken();
    const onFinish = (values) => {
        let params = {
            id: userInfo.id,
            old: values.currentPassword,
            pwd: values.password,
            confirmPwd: values.confirmPassword
        }
        setLoading(true)
        ChangePassword(params).then(res => {
            setLoading(false)
            if (res.code == 0) {
                message.success('Password reset complete');
                history.push(`/?uid=${uid}`)
            } else {
                message.error('Password modification failed, please try again later')
            }
        })
    };
    const onValuesChange = () => {
        console.log('我被触发了')
    }
    const element = (<div className={styles.change_password}>
        <CommonMainLayout>
            <div className={`${styles.main}`}>
                <Row>
                    <Col xs={{ span: 14 }} sm={{ span: 14 }} md={{ span: 14 }} lg={{ span: 14 }} xl={{ span: 14 }}>
                        {userInfo.nickName && <Form
                            name="basic"
                            labelAlign="left"
                            form={form}
                            labelCol={{xs:{span: 10}, sm: {span: 8}, md: {span: 8}, lg: {span: 7}, xl: {span: 7},xxl:{span:5} }}
                            wrapperCol={{ xs:{span: 14}, sm: {span: 14}, md: {span: 14}, lg: {span: 14}, xl: {span: 14},xxl:{span:14} }}
                            initialValues={{ username: userInfo.nickName }}
                            onFinish={onFinish}
                            onValuesChange={onValuesChange}
                            autoComplete="off"
                            className={styles.form}
                        >
                            <Form.Item
                                label="Current Password"
                                maxLength={50}
                                validateFirst
                                validateTrigger={['onChange', 'onBlur']}
                                name="currentPassword"
                                rules={[{ required: true, message: 'Please input your current password!' }, () => ({
                                    validator(_, value) {
                                        if (value.length >= 6) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Password should contain 6 or more characters.'),
                                        );
                                    },
                                }), {
                                    validateTrigger: 'onBlur',
                                    validator: async (_, value) => {
                                        let res = await CheckPwd({ id: userInfo.id, pwd: value })
                                        if (res.code == 0) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                new Error('The current password is wrong'),
                                            );
                                        }
                                    },
                                },]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                dependencies={['currentPassword']}
                                validateFirst
                                name="password"
                                maxLength={50}
                                rules={[{ required: true, message: 'Please input your password!' }, () => ({
                                    validator(_, value) {
                                        if (value.length >= 6) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Password should contain 6 or more characters.'),
                                        );
                                    },
                                }), ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('currentPassword') !== value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password cannot be the same as the old password!'));
                                    },
                                }),]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                dependencies={['password']}
                                label="Confirm Password"
                                validateFirst
                                maxLength={50}
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 6, span: 6 }}>
                                <Button type="primary" htmlType="submit" loading={loading} block size='large'>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>}
                    </Col>
                </Row>

            </div>
        </CommonMainLayout>

    </div>)
    return element
}

export default UserUpdate