import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom'
const NotFound = () => {
    const history = useHistory()
    const element = (<Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={() => { history.push('/') }}>Back Home</Button>}
    />);
    return element
}
export default NotFound