import styles from './index.module.css'
import { Modal, Button, Form, Input, message } from 'antd';
import { getSubscriptionDetails, addTaxInfo, editTaxInfo } from '@/api/Subscription'
import { dealToken } from '@/plugins/utils';
import { useEffect } from 'react';
import { useState } from 'react';
import SuccessIcon from '@/assets/images/SuccessIcon.png'

const InvoicesForm = ({ InvoicesFormStatus, defaultData, handleCancel, initTaxInfo }) => {

    const [SuccessDiaLog, setSuccessDiaLog] = useState(false)

    const CloseSuccessDiaLog = () => {
        setSuccessDiaLog(false)
    }

    const [form] = Form.useForm();
    let uid = dealToken();

    const onFinish = (values) => {
        let addTaxInfoClone = JSON.parse(JSON.stringify(values))
        console.log(defaultData)
        if (!defaultData) {
            addTaxInfoClone.uid = uid
            addTaxInfo(addTaxInfoClone).then(res => {
                if (res.code == 0) {
                    initTaxInfo()
                    setSuccessDiaLog(true)
                    message.success('Add success');
                    handleCancel()
                }
            })
        } else {
            addTaxInfoClone.uid = uid
            addTaxInfoClone.id = defaultData.id
            editTaxInfo(addTaxInfoClone).then(res => {
                if (res.code == 0) {
                    initTaxInfo()
                    setSuccessDiaLog(true)
                    message.success('Edit success');
                    handleCancel()
                }
            })
        }
    }

    useEffect(() => {
        if (defaultData) {
            form.setFieldsValue({
                name: defaultData.name,
                address: defaultData.address,
                company: defaultData.company,
                taxNumber: defaultData.taxNumber,
            })
        }

    }, [defaultData])

    return (
        <>
            {/* from表单 */}
            <Modal title="Fill out the form" zIndex={5} className={'renewalDialog'} visible={InvoicesFormStatus} centered footer={null} onCancel={handleCancel} >
                <div className={styles.subscriptionMain}>
                    <div className={styles.InvoicesDownloadEmail}>
                        Please provide your billing information. (These details will be included in all your future invoices.)
                    </div>
                    <div>
                        <div className={styles.FormHead}>
                            Billing address
                        </div>
                        <Form
                            form={form}
                            name="wrap"
                            labelCol={{ flex: '110px' }}
                            labelAlign="left"
                            onFinish={onFinish}
                            className='InvoicesForm'
                            wrapperCol={{ flex: 1 }}
                            colon={false}
                        >
                            <div className={styles.FormLabel}>Name<span className={styles.FormLabelTxt}>*</span></div>
                            <Form.Item name="name" rules={[{ required: true }]}>
                                <Input className={styles.InvoicesFormItem} />
                            </Form.Item>
                            <div className={styles.FormLabel}>Address<span className={styles.FormLabelTxt}>*</span></div>
                            <Form.Item name="address" rules={[{ required: true }]}>
                                <Input className={styles.InvoicesFormItem} />
                            </Form.Item>
                            <div className={styles.FormLabel}>Company Name<span className={styles.FormLabelTxt}>*</span></div>
                            <Form.Item name="company" rules={[{ required: true }]}>
                                <Input className={styles.InvoicesFormItem} />
                            </Form.Item>
                            <div className={styles.FormLabel}>Tax Number<span className={styles.FormLabelTxt}>*</span></div>
                            <Form.Item name="taxNumber" rules={[{ required: true }]}>
                                <Input className={styles.InvoicesFormItem} />
                            </Form.Item>
                            <Form.Item >
                                <Button type="primary" htmlType="submit" className={styles.submitBtn} >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            {/* 成功弹窗 */}
            <Modal title="Submitted Successfully" zIndex={5} width={400} className={'renewalDialog'} visible={SuccessDiaLog} centered footer={null} onCancel={CloseSuccessDiaLog} >
                <div className={styles.SuccessIconBox}>
                    <img src={SuccessIcon} alt="" className={styles.SuccessIcon} />
                    <p className={styles.SuccessIconTxt}>Submitted Successfully</p>
                </div>
            </Modal>
        </>
    )
}

export default InvoicesForm