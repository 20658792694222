import RegLayout from '@/components/RegAndForgot/RegLayout';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { Form, Input, Button, notification, message } from 'antd';
import { RefoxForgetVerify, RefoxResetPwd } from '@/api/reg/reg';
import utils from '@/plugins/utils.js'
import copy from 'copy-to-clipboard';
const getUserName = (location, setUserName, setStatus) => {
    if (!location.query.data) {
        setStatus(3);
        return false;
    }
    setStatus(1);
    RefoxForgetVerify({ code: location.query.data }).then((res) => {
        if (res.code == 0) {
            setUserName(res.data.name);
            setStatus(2);
        } else {
            setStatus(3);
        }
    });
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
    },
};

const onFinish = (setStep, setLoading, location, values) => {
    // 密码加密
    values.pwd = utils.encrypt(values.pwd);
    values.confirmPwd = utils.encrypt(values.confirmPwd);
    setLoading(true);
    RefoxResetPwd({ ...values, code: location.query.data }).then((res) => {
        if (res.code == 0) {
            setStep(2);
            notification['success']({
                message: 'Tips',
                description: 'Password update success',
            });
        } else {
            notification['error']({
                message: 'Tips',
                description: 'Password update failed',
            });
        }
        setLoading(false);
    });
};

const transformQuery = (search) => {
    if (!search) {
        return { query: {} }
    }
    let queryArr = search.split('?')[1].split('&');
    let obj = {}
    queryArr.map(str => {
        let arr = str.split('=');
        obj[arr[0]] = arr[1]
    })
    return { query: obj }
}
/**
 *  允许loginName 复制到粘贴板上,使用 copy-to-clipboard 插件
 * */
const copyToBoard = (name) => {
    if (copy(name)) {
        message.success('loginName copied to the pasteboard')
    } else {
        message.error('Failed to copy loginName to pasteboard')
    }
}

const ForgotNext = () => {
    const location = transformQuery(useLocation().search);
    const [userName, setUserName] = useState('loading...');
    const [status, setStatus] = useState(1); // 1 - loading 2 - success 3 - link fail
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    useEffect(() => {
        getUserName(location, setUserName, setStatus);
    }, []);
    const element = (<RegLayout>
        {step == 1 ? (
            <>
                <p className={`cf5 size18`}>EMAIL VERIFICATION </p>
                <p className="size18">
                    Please reset your password. (Password should contain 6 or more
                    characters. Leading or trailing spaces will be ignored.)
                </p>
                {status == 1 ? (
                    <p className="size16">Loading...</p>
                ) : status == 3 ? (
                    <p className="size16 ">Oops! The link has broken</p>
                ) : (
                    <>
                        <p onClick={() => copyToBoard(userName)}>
                            UserName: <span className="c6 bold pointer">{userName}</span>
                        </p>
                        <Form
                            name="nest-messages"
                            onFinish={(values) =>
                                onFinish(setStep, setLoading, location, values)
                            }
                            validateMessages={validateMessages}
                        >
                            <Form.Item
                                name="pwd"
                                hasFeedback
                                rules={[
                                    { required: true, message: 'Please input your password!' },
                                    () => ({
                                        validator(_, value) {
                                            if (value.length >= 6) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Password should contain 6 or more characters.',
                                                ),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                name="confirmPwd"
                                dependencies={['pwd']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('pwd') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'The two passwords that you entered do not match!',
                                                ),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    SUBMIT
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
            </>
        ) : (
            <>
                <p className={`cf5 size18`}>NEW PASSWORD </p>
                {status == 2 ? (
                    <>
                        <p className="size16">
                            UserName: <span className="c6 bold">{userName}</span>
                        </p>
                        <p className="size16">
                            Success to send reset password,Now you can log in.
                        </p>
                    </>
                ) : status == 1 ? (
                    <p className="size16">Loading...</p>
                ) : (
                    <p className="size16">Oops! The link has broken</p>
                )}
            </>
        )}
    </RegLayout>);
    return element
}
export default ForgotNext