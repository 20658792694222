import { Form, Input, Upload, Modal, Button,message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import request, { BaseUrl } from '@/api/request'
import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { FeedbackReq } from '@/api/user/user';
import styles from './index.module.css';
import useCounterModel from '@/model/user';
import { dealToken } from '@/plugins/utils';
const { TextArea } = Input
const FeedbackMainModule = ({setIsModalVisible}) => {

    const [form] = Form.useForm();

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const [btnLoading, setBtnLoading] = useState(false)

    let uid = dealToken();
    const onFinish = (value) => {

        if(uid){
            
            let params = { uid: uid, title: value.title, content: value.content };
       
            if(value && value.fileList){
             let arr = []
             value.fileList.fileList.map(Sitem=>{
                 arr.push(Sitem.response.data)
             })
             params.image = arr.join(',')
            }

            FeedbackReq(params).then(res => {
                setBtnLoading(false)
                form.resetFields()
                if (res.code == 0) {
                    message.success('Feedback has been submitted, please wait for customer service to process', 5);
                    setIsModalVisible(false)
                } else {
                    message.error('Feedback submission failed, please try again later', 5)
                }
            })
        }

       
    }
    const onFinishFailed = () => {

    }

    const closeModel = () => {
        setIsModalVisible(false)
    }
    const handlePreview = async (file) => {
        setPreviewImage(file.response.data);
        setPreviewVisible(true);
    };
    return (
        <div>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 3 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >

                <Form.Item
                    label="title:"
                    name="title"
                    onFinish
                    rules={[{ required: true, message: 'Please enter your suggestions or feedback!' }]}
                >
                    <Input style={{height: '40px'}} placeholder='Please enter your suggestions or feedback' />
                </Form.Item>
                <Form.Item label="content:" name="content" rules={[{ required: true, message: 'Please enter content!' }]}>
                    <TextArea rows={4} placeholder='Please enter content!' />
                </Form.Item>
                <Form.Item label="Image" name="fileList">
                    <Upload onPreview={handlePreview} multiple action={BaseUrl + '/soft/web/upload'} accept={'.jpg,.png,.jpeg'} maxCount="5" listType="picture-card">
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    </Upload>
                </Form.Item>
                <Form.Item className={styles.CencelBtnContenForm}>
                    <div className={styles.CencelBtnConten}>
                        <Button loading={btnLoading} className={styles.CencelBtn} htmlType="button" onClick={closeModel}>
                            Cancel
                        </Button>
                        <Button loading={btnLoading} className={styles.SubmitBtn} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </div>
                </Form.Item>
            </Form>
            <Modal
                visible={previewVisible}
                title='View Image'
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    )
}

export default FeedbackMainModule