import RegLayout from '@/components/RegAndForgot/RegLayout';
import { useEffect, useState } from 'react';
import { RefoxActiveUser } from '@/api/reg/reg';
import { useLocation } from 'react-router-dom';
import{message} from 'antd';
import copy from 'copy-to-clipboard';

const activeAccount = (location, setTip, setStatus, setLoginName) => {
    if (!location.query.code) {
        setStatus(3);
        return false;
    }
    if (!location.query.name) {
        setStatus(3);
        return false;
    }
    setStatus(1);
    RefoxActiveUser({
        code: location.query.code,
        name: location.query.name,
    }).then((res) => {
        if (res.code == 0) {
            setLoginName(res.data.loginName);
            setStatus(2);
        } else {
            setTip(res.msg);
            setStatus(3);
        }
    });
};

const transformQuery = (search) => {
    if (!search) {
        return { query: {} }
    }
    let queryArr = search.split('?')[1].split('&');
    let obj = {}
    queryArr.map(str => {
        let arr = str.split('=');
        obj[arr[0]] = arr[1]
    })
    return { query: obj }
}
/**
 *  允许loginName 复制到粘贴板上,使用 copy-to-clipboard 插件
 * */
const copyToBoard = (name)=>{
    if(copy(name)){
        message.success('loginName copied to the pasteboard')
    }else{
        message.error('Failed to copy loginName to pasteboard')
    }
}

const RegNext = (props) => {
    const location = transformQuery(useLocation().search);
    const [tip, setTip] = useState('');
    const [status, setStatus] = useState(1); // 1 - 请求还未回来之前 2 - 请求回来之后 成功 3 - 请求回来之后 失败
    const [loginName, setLoginName] = useState('');
    useEffect(() => {
        activeAccount(location, setTip, setStatus, setLoginName);
    }, []);
    const element = (<RegLayout>
        <p className={`cf5 size18`}>The Refox Team Just for Smart Repair</p>
        {status == 1 ? (
            <p className={`size16`}>Activating, please wait...</p>
        ) : status == 2 ? (
            <>
                <p className={`size16 c6`} onClick={()=>copyToBoard(loginName)}>
                    <span>Login name: </span><span className='bold pointer'>{loginName}</span>
                </p>{' '}
                <p className={`size16`}>
                    <span className="c6 bold">Activation success:</span> The account
                    register success! Now you can log in
                </p>
            </>
        ) : (
            <p className={`size16`}>
                <span className="c6 bold">Activation fails:</span> {tip}
            </p>
        )}
    </RegLayout>);
    return element
}
export default RegNext