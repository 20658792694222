import { useState } from 'react';
import RegLayout from '@/components/RegAndForgot/RegLayout';
import RegisterFirst from '@/components/RegAndForgot/RegisterFirst';
import RegisterSecond from '@/components/RegAndForgot/RegisterSecond';

const RegisterMain = () => {
    const [step, setStep] = useState(1);
    const [eamil, setEamil] = useState('');
    const element = (<RegLayout>
        <div style={{ display: step == 1 ? 'block' : 'none' }}>
            {RegisterFirst({ setEamil, setStep })}
        </div>
        <div style={{ display: step == 2 ? 'block' : 'none' }}>
            {RegisterSecond(eamil)}
        </div>
    </RegLayout>);
    return element
}
export default RegisterMain