import { Image } from 'antd';
const CommonImage = (props) => {
    const element = (<Image
        preview={false}
        width={props.width}
        height={props.height}
        src={props.url}
    />)
    return element
}

export default CommonImage