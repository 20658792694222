import styles from './index.module.css'
import { useState, useEffect } from 'react'
import './index.style.css'
import { Collapse, Divider, BackTop, Pagination, Spin, Alert } from 'antd';
import CommonMainLayout from '@/components/CommonMainLayout';
import backtop from '@/assets/images/backtop.png';
import { getFAQ } from '../../api/Faq'
const { Panel } = Collapse;
const FaqMain = () => {
    const [faqlist, setfaqlist] = useState([])
    const [zpageNum, setpageNum] = useState(1)
    const [zpageSize, setpageSize] = useState(10)
    const [pagetotal, settotal] = useState(0)
    useEffect(() => {
        getfaqdata()
    }, [0])
    const getfaqdata = (params) => {
        let pagedate
        if (!params) {
            pagedate = {
                pageNum: zpageNum,
                pageSize: zpageSize
            }
        } else {
            pagedate = params
        }
        getFAQ(pagedate).then(res => {
            if (res.code == 0) {
                setfaqlist(res.rows)
                settotal(res.total)
            }
        })
    }

    const changepagedata = (page, pageNum) => {
        setpageNum(page)
        let pagedate = {
            pageNum: page,
            pageSize: zpageSize
        }
        getfaqdata(pagedate)
    }

    const callback = () => { }
    const element = (<div className={styles.faq_main}>
        <CommonMainLayout>
            <div className={`${styles.main}`}>
                <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17']} bordered={false} expandIconPosition={'right'} onChange={callback}>
                    {faqlist.map((item, index) => <Panel header={item.quesion} key={item.id} className={`${styles.panel} faq_panel`}><div dangerouslySetInnerHTML={{ __html: item.answer }}></div></Panel>)}
                </Collapse>
                <div className='Pagination-center'>
                    <Pagination current={zpageNum} pageSize={zpageSize} total={pagetotal} center onChange={changepagedata} />
                </div>
            </div>
            <BackTop>
                <div>
                    <img src={backtop}></img>
                </div>
            </BackTop>
        </CommonMainLayout>
    </div>)
    return element
}
export default FaqMain