import styles from './index.module.css';
import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Modal, Button, Upload, Input, message, Spin } from 'antd';
import UploadFile from '@/components/UploadFile'
import { PlusOutlined } from '@ant-design/icons';
import { UploadReq, FeedbackReq } from '@/api/user/user'
const { TextArea } = Input;
const Feedback = forwardRef((props, ref) => {
    const { userInfo } = props
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [currentFile, setCurrentFile] = useState();
    const [loading, setLoading] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [btnLoading, setBtnLoading] = useState(false)
    const [msg, setMsg] = useState('');
    const textArea = useRef()

    useImperativeHandle(ref, () => ({
        openModal: openModal
    }))
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    // 打开弹框
    const openModal = () => {
        setIsModalVisible(true)
    }
    // 预览
    const handlePreview = (file) => {
        setPreviewImage(file.url);
        setPreviewTitle(file.name)
        setPreviewVisible(true)
    }
    const handleCancelPreview = () => {
        setPreviewVisible(false)
    }
    const handleChange = ({ file, fileList }) => {
        // console.log(file, fileList)
    }
    const UploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const onChange = (e) => {
        setMsg(e.target.value)
    }
    const onRemove = (file) => {
        let index = fileList.findIndex(f => f.uid == file.uid)
        let fileListNew = JSON.parse(JSON.stringify(fileList))
        fileListNew.splice(index, 1);
        setFileList(fileListNew)
    }
    const beforeUpload = (file, fileList) => {
        let isLt1M = file.size / 1024 / 1024 < 1
        if (!isLt1M) {
            message.error(`${file.name} exceed the upload size limit, please modify and upload again`, 2);
            return false
        } else {
            setCurrentFile(file)
            return true
        }
    }
    // 自定义上传
    const customRequest = () => {
        setLoading(true)
        UploadReq({ file: currentFile }).then(res => {
            setLoading(false)
            if (res.code == 0) {
                let fileListNew = JSON.parse(JSON.stringify(fileList))
                fileListNew.push({
                    uid: fileListNew.length,
                    name: currentFile.name,
                    status: 'done',
                    url: res.msg,
                })
                setFileList(fileListNew)
            }
        })
    }
    // 提交
    const handleSubmit = () => {
        if ((msg.trim() == '' || !msg) && !fileList.length) {
            message.error('Please leave a message')
            return false
        }
        let params = { userId: userInfo.id };
        if (msg.trim() !== '') {
            params.content = msg
        }
        if (fileList.length > 0) {
            let arr = [];
            fileList.map(file => {
                arr.push(file.url)
            })
            params.image = arr.join(',')
        }

        console.log(textArea)
        setBtnLoading(true)
        FeedbackReq(params).then(res => {
            setBtnLoading(false)
            if (res.code == 0) {
                // 提交成功则删除记录
                setFileList([]);
                setMsg("")
                message.success('Feedback has been submitted, please wait for customer service to process', 3);
                setIsModalVisible(false)
            } else {
                message.error('Feedback submission failed, please try again later', 3)
            }
        })
    }
    const element = (<Modal title="Submit feedback" visible={isModalVisible} className={`feedback`} width={690} footer={null} onCancel={handleCancel}>
        <div className={styles.main}>
            <TextArea rows={8} onChange={onChange} ref={textArea} className={styles.textarea_input} maxLength={500} showCount bordered={false} placeholder='Please enter feedback' value={msg} allowClear={true} />
            {/* <UploadFile beforeUpload={beforeUpload} customRequest={customRequest} fileList={fileList} /> */}
            <Spin spinning={loading} >
                <Upload
                    accept=".png,.jpg,.jpeg"
                    action={``}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    onRemove={onRemove}
                    customRequest={customRequest}
                    beforeUpload={beforeUpload}
                >
                    {fileList.length >= 5 ? null : UploadButton}
                </Upload>
            </Spin>
        </div>
        <div className={styles.footer_btn}>
            <Button type="primary" shape="round" loading={btnLoading} onClick={handleSubmit} size={'large'} block>
                Submit
            </Button>
        </div>
        <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancelPreview}
        >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
    </Modal>);
    return element
})
export default Feedback