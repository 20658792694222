import { useState } from 'react';
import { Form, Input, Button, Select, notification } from 'antd';
import { getCountry, RefoxVerifyEmail, RefoxUserReg } from '@/api/reg/reg';
import utils from '@/plugins/utils.js'
const { Option } = Select;


const getCountryList = (props) => {
    let { setCountry, setLoading } = props;
    setLoading(true);
    getCountry().then((res) => {
        if (res.code == 0) {
            res.data.map((item, index) => {
                item.index = index;
            });
            setCountry(res.data);
        }
        setLoading(false);
    });
};
const validateModeRequire = (
    setEmailFeedback,
    setEmailStatus,
    rule,
    value,
) => {
    if (!value || value.trim() == '') {
        setEmailStatus(2);
        setEmailFeedback(false);
        return Promise.reject('please enter your email');
    }
    var regEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+(\.[a-zA-Z0-9_.-]+)+$/;
    if (!regEmail.test(value)) {
        setEmailStatus(2);
        setEmailFeedback(true);
        return Promise.reject('Please enter the correct email format');
    } else {
        setEmailStatus(0);
        setEmailFeedback(false);
        return Promise.resolve();
    }
};

// email change 验证
const validateMode = (
    setEmailFeedback,
    setEmailStatus,
    rule,
    value,
) => {
    var regEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+(\.[a-zA-Z0-9_.-]+)+$/;
    if (!value || value.trim() == '') {
        setEmailFeedback(false);
        return Promise.reject('please enter your email');
    }
    if (!regEmail.test(value)) {
        setEmailStatus(2);
        setEmailFeedback(true);
        return Promise.reject('Please enter the correct email format');
    } else {
        setEmailStatus(0);
        setEmailFeedback(false);
        return Promise.resolve();
    }
};

// email blur 验证
const validateModeBlur = async (
    setEmailFeedback,
    setEmailStatus,
    rule,
    value,
) => {
    if (!value || value.trim() == '') {
        return Promise.resolve();
    }
    var regEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+(\.[a-zA-Z0-9_.-]+)+$/;
    if (!regEmail.test(value)) {
        setEmailStatus(2);
        setEmailFeedback(true);
        return Promise.reject('Please enter the correct email format');
    } else {
        setEmailStatus(3);
        // 验证通过则发请求
        let res = await RefoxVerifyEmail({ email: value });
        if (res.code !== 0) {
            setEmailStatus(2);
            setEmailFeedback(true);
            return Promise.reject(res.msg);
        } else {
            setEmailStatus(1);
            setEmailFeedback(true);
            return Promise.resolve();
        }
    }
};

const onFinish = (
    setEamil,
    setStep,
    setBtnLoading,
    values,
) => {
    values.password = utils.encrypt(values.password);
    values.confirmPassword = utils.encrypt(values.confirmPassword);
    setBtnLoading(true);
    // 验证通过后提交数据注册
    RefoxUserReg(values).then((res) => {
        if (res.code == 0) {
            // 说明注册成功
            notification['success']({
                message: 'Tips',
                description: 'register success',
            });
            setEamil(values.email);
            setStep(2);
        } else {
            notification['error']({ message: 'Tips', description: res.msg });
        }
        setBtnLoading(false);
    });
};

const RegisterFirst = (props) => {
    const { setEamil, setStep } = props;
    const [form] = Form.useForm();
    const [country, setCountry] = useState([]);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [emailStatus, setEmailStatus] = useState(0); // 0 - 未验证 1 - 验证通过 2 - 验证未通过 3 - 正在验证中
    const [emailFeedback, setEmailFeedback] = useState(false);
    const element = (<>
        <p className={`cf5 size18`}>The Refox Team Just for Smart Repair</p>
        <Form
            name="control-ref"
            onFinish={(values) =>
                onFinish(setEamil, setStep, setBtnLoading, values)
            }
            form={form}
        >
            <Form.Item
                name="email"
                validateFirst={true}
                hasFeedback={emailFeedback}
                validateStatus={
                    emailStatus == 0
                        ? ''
                        : emailStatus == 1
                            ? 'success'
                            : emailStatus == 3
                                ? 'validating'
                                : 'error'
                }
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                    {
                        required: true,
                        validateTrigger: 'onChange',
                        validator: (rule, value) =>
                            validateModeRequire(
                                setEmailFeedback,
                                setEmailStatus,
                                rule,
                                value,
                            ),
                    },
                    {
                        validateTrigger: 'onBlur',
                        validator: (rule, value) =>
                            validateModeBlur(setEmailFeedback, setEmailStatus, rule, value),
                    },
                    {
                        validateTrigger: 'onChange',
                        validator: (rule, value) =>
                            validateMode(setEmailFeedback, setEmailStatus, rule, value),
                    },
                ]}
            >
                <Input placeholder="please enter your email" />
            </Form.Item>
            <Form.Item
                name="password"
                validateFirst={true}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                    { required: true, message: 'please enter password' },
                    () => ({
                        validator(_, value) {
                            if (value.length >= 6) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error('Password should contain 6 or more characters.'),
                            );
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="please enter password" />
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                validateFirst={true}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                    { required: true, message: 'please enter confirm password' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error('The two passwords that you entered do not match!'),
                            );
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="please enter confirm password" />
            </Form.Item>
            <Form.Item
                name="nickName"
                rules={[{ required: true, message: 'please enter your name' }]}
            >
                <Input placeholder="please enter your name" />
            </Form.Item>
            <Form.Item
                name="telphone"
                rules={[{ required: true, message: 'please enter your telphone' }]}
            >
                <Input placeholder="please enter your telphone" />
            </Form.Item>
            <Form.Item
                name="country"
                rules={[{ required: true, message: 'please select your country' }]}
            >
                <Select
                    allowClear
                    showSearch
                    onDropdownVisibleChange={() =>
                        getCountryList({ setCountry, setLoading })
                    }
                    placeholder="Select country / region"
                    optionFilterProp="children"
                    loading={loading}
                    filterOption={(input, option) =>
                        option.type.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {country.map((item) => (
                        <Option
                            value={item.countryNameEn}
                            key={item.countryNo}
                            type={item.countryNameEn}
                        >
                            {item.countryNameEn}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="country"
                rules={[{ required: true, message: 'please select your country' }]}
            >
                <Select
                    allowClear
                    showSearch
                    onDropdownVisibleChange={() =>
                        getCountryList({ setCountry, setLoading })
                    }
                    placeholder="Select country / region"
                    optionFilterProp="children"
                    loading={loading}
                    filterOption={(input, option) =>
                        option.type.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {country.map((item) => (
                        <Option
                            value={item.countryNameEn}
                            key={item.countryNo}
                            type={item.countryNameEn}
                        >
                            {item.countryNameEn}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    danger
                    className={`create_btn`}
                    loading={btnLoading}
                    htmlType="submit"
                >
                    CREATE AN ACCOUNT
                </Button>
                <Button>CANCEL</Button>
            </Form.Item>
        </Form>
    </>);
    return element
}
export default RegisterFirst