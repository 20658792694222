const RegisterSecond = (eamil) => {
    const element = (<>
        <p className={`cf5 size18`}>The Refox Team Just for Smart Repair</p>
        <p className={`cf5 size18`}>register success</p>
        <p className={`size16`}>
            <span className="c6">register email:</span>
            {eamil}
        </p>
        <p className={`size16 bold c6`}>
            please open your email to actived refox team account!
        </p>
    </>);
    return element
}
export default RegisterSecond