import { useState } from 'react'
import { createModel } from 'hox';

/* 逻辑原样复制过来 */
function useCounter() {
    const [count, setCount] = useState();
    const [userInfo, setUserInfo] = useState({});
    const [loginFail, setLoginFail] = useState(false);
    const [uid, setToken] = useState('');
    const decrement = () => setCount(count - 1);
    const increment = () => setCount(count + 1);
    const setUser = (info) => setUserInfo(info);
    const setLogin = (flag) => setLoginFail(flag);
    const setTokenFun = (uid) => setToken(uid);
    return {
        count,
        userInfo,
        loginFail,
        uid,
        setTokenFun,
        setLogin,
        setUser,
        decrement,
        increment
    };
}
/* 用 createModel 包一下就行 */
export default createModel(useCounter)