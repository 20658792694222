import request, { BaseUrl } from '@/api/request'
const RefoxCountryApi = `${BaseUrl}/web/country/list`; // 获取国家的接口
const RefoxApplyApi = `${BaseUrl}/web/apply`; // 发送批发申请
const RefoxGetDictApi = `${BaseUrl}/web/wholesale/dict`; // 获取对应字典值
const RefoxUserRegApi = `${BaseUrl}/interface/member/register`; // 用户注册
const RefoxResetPwdApi = `${BaseUrl}/interface/member/reset`; // 重置密码
const RefoxActiveUserApi = `${BaseUrl}/interface/member/verify`; // 激活用户
const RefoxForgetPwdApi = `${BaseUrl}/interface/member/forget`; // 忘记密码
const RefoxForgetVerifyApi = `${BaseUrl}/interface/member/forget/verify`; // 用户忘记密码验证
const RefoxVerifyEmailApi = `${BaseUrl}/interface/member/email-verify`; // 验证邮箱是否注册

/**
 * 获取国家列表
 */
export const getCountry = () => {
    return request("get", RefoxCountryApi)
}

/**
 * 发送批发申请
 * @param {*} params title
 * @returns 
 */
export const postAgent = (params) => {
    return request("post", RefoxApplyApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 获取对应字典值
 */
export const refoxGetDict = () => {
    return request("get", RefoxGetDictApi)
}

/**
 * 用户注册
 * @param {*} params title
 * @returns 
 */
export const RefoxUserReg = (params) => {
    return request("post", RefoxUserRegApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 重置密码
 * @param {*} params title
 * @returns 
 */
export const RefoxResetPwd = (params) => {
    return request("post", RefoxResetPwdApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 激活用户
 * @param {*} params title
 * @returns 
 */
export const RefoxActiveUser = (params) => {
    return request("get", RefoxActiveUserApi, params)
}


/**
 * 忘记密码
 * @param {*} params title
 * @returns 
 */
export const RefoxForgetPwd = (params) => {
    return request("post", RefoxForgetPwdApi, params, 'form', { "Content-Type": "multipart/form-data" })
}


/**
 * 用户忘记密码验证
 * @param {*} params title
 * @returns 
 */
export const RefoxForgetVerify = (params) => {
    return request("get", RefoxForgetVerifyApi, params, 'form', { "Content-Type": "multipart/form-data" })
}

/**
 * 验证邮箱是否注册
 * @param {*} params title
 * @returns 
 */
export const RefoxVerifyEmail = (params) => {
    return request("get", RefoxVerifyEmailApi, params)
}
