import { Button, Input, Divider, Spin, Result, message, Modal, Row, Col } from 'antd';
import styles from './index.module.css';
import CardBg from '@/assets/images/card_bg220317.png';
import CommonImage from '@/components/CommonImage/index';
import PayDialog from '@/components/PayDialog'
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { getProducts, payInfo, payCode, createOrderInfo, } from '@/api/home/home';
import { getUserInfoapi } from '@/api/rewards/index'
import CommonMainLayout from '@/components/CommonMainLayout';
import useCounterModel from '@/model/user';
import { dealToken } from '@/plugins/utils';
import payIcon from '@/assets/images/payIcon.png';
import RussiaUsers from '@/assets/images/RussiaUsers.png';
import ThailandUsers from '@/assets/images/ThailandUsers.png';
import TurkeyUsers from '@/assets/images/TurkeyUsers.png';

import banner from '@/assets/images/sharebanner.png';
import { Link } from 'react-router-dom';

import { supplierUrlApi } from '@/api/home/home'



const dealPrice = (price) => {
    let newPrice = price.split('.')
    return <p>$ <span className={`${styles.price_num}`}>{newPrice[0]}</span>.{newPrice[1]}</p>
}


const Serverpin = ({ state, closefun, uids }) => {
    let uid = dealToken();
    if (state) {
        return (
            <div className={styles.serverpin}>
                <div className={styles.closemask} onClick={() => { closefun() }}>
                    &nbsp;
                </div>
                <div className={styles.serverpinimg} onClick={() => { closefun() }}>
                    <Link to={'/getrewards?uid=' + uid}>
                        <CommonImage url={banner} width={'100%'} />
                    </Link>
                </div>
            </div>
        )
    } else {
        return <></>
    }

}

const Card = (props) => {
    const { card, setPayFlag, userInfo, uid, btnLoading, setBtnLoading, setPayDetail, setpaypalkey } = props;
    const [lodingtype, setlodingtype] = useState('')
    const handleOpenPay = () => {
        setpaypalkey(1)
        setlodingtype(card.id)
        setBtnLoading(true)
        payInfo({ userId: uid, productId: card.id }).then(res => {
            setBtnLoading(false)
            setlodingtype('')
            if (res.code == 0) {
                setPayDetail(res.data)
                setPayFlag(true)
            } else {
                message.error('Network failure, please try again later');
            }
        })
    }
    const element = (
        <div className={`${styles.home_main_card} fl`}>
            <div className={styles.bg_wrap}>
                {/* <CommonImage url={CardBg} width={298} /> */}
                {/* <img src={CardBg} width={'100%'} /> */}
            </div>
            <div className={styles.card_content_wrap}>
                <div className={styles.card_title}>{card.name}</div>
                <div className={styles.card_label}>{card.subName}</div>
                <div className={styles.card_price}>{dealPrice(card.price.toFixed(2))}</div>
                <Divider />
                {
                    userInfo.subOrder && userInfo.productId == card.id ? <Button block size="large" type="info" shape="round" className={`main_card_btn ${styles.NotBuyButton}`}>Current Plan</Button> : <Button block size="large" type="primary" shape="round" className={`main_card_btn ${styles.buyButton}`} onClick={() => { handleOpenPay() }} disabled={btnLoading} loading={lodingtype == card.id}>{userInfo.subOrder ? 'Change Plan' : 'Buy Now'}</Button>
                }

            </div>
        </div>)
    return element
}

const HomeMain = () => {

    const [sharemask, setsharemask] = useState(false)
    const [list, setList] = useState([]);
    const [listLoading, setListLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false)
    const [payFlag, setPayFlag] = useState(false);
    const [code, setCode] = useState('');
    const [resultModal, setResultModal] = useState(false);
    const [resultFlag, setResultFlag] = useState(false);
    const { userInfo, uid } = useCounterModel();
    const [payDetail, setPayDetail] = useState(null);
    const [payLoading, setPayLoading] = useState(false);
    const [freameModal, setFreameModal] = useState(false);
    const [payUrl, setPayUrl] = useState('')
    const payDialog = useRef()
    const { id } = userInfo
    const [sendajaxstate, setsendstatus] = useState(0)
    const [userinfos, setuserinfo] = useState({})
    const [paypalkey, setpaypalkey] = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false)

    let uids = dealToken();

    const [errorMsg, setErrorMsg] = useState('')

    const [reloadPage, setReloadPage] = useState(false)

    const closeReloadModal = () => {
        setReloadPage(false)
    }

    const ReloadPage = () => {
        window.location.reload()
    }

    useEffect(() => {

        getProduct()
        if (uids) {
            updateduserinfo()
        }

        let sharepoup = window.localStorage.getItem('sharepoup')
        if (sharepoup) {
            setsharemask(false)
        } else {
            setsharemask(true)
        }

    }, ['sharemask'])


    const updateduserinfo = () => {
        getUserInfoapi(uids).then(res => {
            if (res.code == 0) {
                setuserinfo(res.data)
            }

        })
    }

    const closemaskbox = () => {
        setsharemask(false)
        window.localStorage.setItem('sharepoup', true)
    }


    const getProduct = () => {
        setListLoading(true)
        getProducts().then(res => {
            setListLoading(false)
            if (res.code == 0) {
                setList(res.data)

            }
            setsendstatus(1)
        })
    }

    const generateRandomNumber = () => {
        var min = 100000; // 最小值（包含）
        var max = 999999; // 最大值（包含）
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const handleToPay = (params) => {
        let newParams = { ...params, userId: id }
        // console.log(payDialog)
        createOrderInfo(newParams).then(res => {
            setPayLoading(false)
            if (res.code == 0) {
                if (newParams.paymentMethodId == 4) {
                    setPayFlag(false)
                    setpaypalkey(0)
                    message.success('Purchase Successfully');
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    return false
                }
                // window.open(encodeURI(res.msg))
                // console.log(encodeURI(res.msg))
                window.localStorage.setItem('orderNo', res.data.orderNo)
                // if (params.paymentMethodId == 3) {
                //     setReloadPage(true)
                //     document.title = res.data.url + '?token=' + generateRandomNumber()
                // } else {
                //     window.location.href = res.data.url
                // }
                setReloadPage(true)
                document.title = res.data.url + '?token=' + generateRandomNumber()
                // var a = document.createElement('iframe');
                // a.src = res.msg; //add your iframe path here
                // a.width = "1000";
                // a.height = "500";
                // document.querySelector('body').appendChild(a)
                // setFreameModal(true);
                // setPayUrl(res.msg)
            }
        })
    }
    const handleCodeBuy = () => {
        if (code.trim() == '') {
            return false
        }
        payCode({ code: code, uid: id }).then(res => {
            setResultModal(true)
            if (res.code == 0) {
                setResultFlag(true);
                message.success('The purchase is successful, please wait...');
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            } else {
                setResultFlag(false)
                setErrorMsg(res.msg)
                message.error(res.msg);
            }
        })
    }
    const onChange = (e) => {
        e.target.value = e.target.value.trim();
        setCode(e.target.value.trim())
    }
    const handleOk = () => {
        window.location.reload()
        setResultModal(false)
    }
    const handleCancel = () => {
        setResultModal(false)
    }
    const handleCancelPay = () => {
        setFreameModal(false);
    }

    const PageNextTo = (name, url) => {
        supplierUrlApi({ name, url, uid })
        document.title = url + '?token=' + generateRandomNumber()
    }

    if (sendajaxstate == 0) {
        return (
            <CommonMainLayout>
                <div className={`${styles.main} ${styles.loddingbox}`}>
                    <Spin tip="Loading...">
                    </Spin>
                </div>
                {/* <Serverpin state={sharemask} closefun={closemaskbox} uid={id} /> */}
            </CommonMainLayout>)
    } else {
        return (<div className={`${styles.home_main}`}>
            {!id ? <Result
                status="500"
                title="500"
                subTitle="Sorry, the account was not successfully logged in, please log in again"
            /> : <CommonMainLayout><div className={`${styles.main}`}>
                {/* <Serverpin state={sharemask} closefun={closemaskbox} uid={id} /> */}
                <Spin spinning={listLoading}>
                    <div className="main_card_wrap clearfix">
                        <Row justify="space-around">
                            {list.map(card => <Card card={card} key={card.id} uid={id} btnLoading={btnLoading} setPayDetail={setPayDetail} setBtnLoading={setBtnLoading} userInfo={userInfo} setPayFlag={setPayFlag} setpaypalkey={setpaypalkey} />)}
                        </Row>
                    </div>
                </Spin>
                <Row justify="space-around">
                    <Col xs={{ span: 23 }} sm={{ span: 23 }} md={{ span: 23 }} lg={{ span: 23 }} xl={{ span: 23 }}>
                        <div className={`${styles.main_input_active} clearfix`}>
                            <Input placeholder="Please enter the activation code" size="large" onChange={(e) => onChange(e)} className={`${styles.active_input} fl`} />
                            <Button block size="large" type="primary" onClick={handleCodeBuy} className={`${styles.active_btn} fl`}>Apply</Button>
                        </div>
                    </Col>
                    <p onClick={() => { setIsModalOpen(true) }} className={styles.UrlLinkTo}>Check Authorized Reseller Here {'>>'}</p>
                </Row>
                {payDetail ? <PayDialog payDetail={payDetail} key={paypalkey} userinfo={userinfos} payLoading={payLoading} setPayLoading={setPayLoading} ref={payDialog} payFlag={payFlag} handleToPay={handleToPay} setPayFlag={setPayFlag} setpaypalkey={setpaypalkey} /> : false}
                <Modal title="Purchase result" visible={resultModal} onOk={handleOk} onCancel={handleCancel}>
                    {resultFlag ? <Result
                        status="success"
                        title="The purchase is successful, please wait..."
                    /> : <Result
                        status="error"
                        title={errorMsg}
                    ></Result>}
                </Modal>
                <Modal visible={freameModal} onOk={handleOk} onCancel={handleCancelPay}>
                    <iframe width={500} height={500} src={payUrl}></iframe>
                </Modal>
                {/* 供应商弹窗 */}
                <Modal title="Authorized Reseller List" footer={null} visible={isModalOpen} onCancel={() => { setIsModalOpen(false) }} className={'AuthorizedModalCss'}>
                    <div className={styles.AuthorizedBox}>
                        <div className={styles.AuthorizedItem}>
                            <div className={styles.AuthorizedItemLeft}>
                                <img src={RussiaUsers} className={styles.gongYingShan}></img>
                                <span>Aliexpress (Russia Users)</span>
                            </div>
                            <div className={styles.AuthorizedItemRight} onClick={() => { PageNextTo('Russia Users', 'https://www.aliexpress.us/item/3256804534104716.html') }}>
                                Order Now {'>>'}
                            </div>
                        </div>
                        <div className={styles.AuthorizedItem}>
                            <div className={styles.AuthorizedItemLeft}>
                                <img src={ThailandUsers} className={styles.gongYingShan}></img>
                                <span>Facebook (Thailand Users)</span>
                            </div>
                            <div className={styles.AuthorizedItemRight} onClick={() => { PageNextTo('Thailand Users', 'https://www.facebook.com/champismartservice/posts/pfbid0TfHuu6R2T7YgjoR1jBSz7PR8C8EVPxzjsvyo3168rGCEfE7gMsXfMhBa1f3gtDTbl') }}>
                                Order Now {'>>'}
                            </div>
                        </div>
                        <div className={styles.AuthorizedItem}>
                            <div className={styles.AuthorizedItemLeft}>
                                <img src={TurkeyUsers} className={styles.gongYingShan}></img>
                                <span>Website (Turkey Users)</span>
                            </div>
                            <div className={styles.AuthorizedItemRight} onClick={() => { PageNextTo('Turkey Users', 'https://server.halabtech.com/resellerpricing/server') }}>
                                Order Now {'>>'}
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* 刷新页面 */}
                <Modal visible={reloadPage} footer={null} title={'Payment Processing'} onCancel={closeReloadModal} className={'reloadPageCss'} maskClosable={false}>
                    <div>
                        <img src={payIcon} className={styles.reloadPageIcon}></img>
                        <p className={styles.reloadPageText}>If you have completed the payment,<br></br> Please click here to update your order status.</p>
                        <button className={styles.reloadPageButton} onClick={ReloadPage}>Update to Complete</button>
                    </div>
                </Modal>
            </div></CommonMainLayout>}
        </div>)
    }
}
export default HomeMain