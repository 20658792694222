import styles from './index.module.css';
import { Empty } from 'antd';
import { useRef, useEffect, useState } from 'react';
// import Viewer from 'react-viewer'
import { PhotoSlider } from 'react-photo-view';
import 'react-photo-view/dist/index.css';
const CaseDesc = (props) => {
    const { className, detail } = props;
    const caseDom = useRef();
    const [visible, setVisible] = useState(false);
    const [currentImg, setCurrentImg] = useState([]);
    const [descH, setDescH] = useState(600)
    const handleClickImg = (e) => {
        let url = e.target.currentSrc;
        if (e.target.currentSrc.includes('?x-oss-process=')) {
            url = e.target.currentSrc.split('?x-oss-process=')[0];
        }
        url = `${url}?x-oss-process=image/interlace,1/quality,q_100`
        setCurrentImg([url])
        setVisible(true)
    }
    // 抓取详情页中所有的img，并赋予事件
    const getAllImgByDom = () => {
        let dom = caseDom.current;
        if (!dom) {
            return false
        }
        let imgArr = dom.getElementsByTagName('img') || [];
        if (!imgArr.length) {
            return false
        }
        for (let i = 0; i < imgArr.length; i++) {
            imgArr[i].onclick = handleClickImg
        }
    }
    const getWindH = () => {
        let windowH = window.innerHeight;
        let newDescH = windowH - 80
        setDescH(newDescH)
    }
    const onResize = () => {
        getWindH();
    }
    // const handleClose = () => {
    //     setVisible(false)
    // }
    useEffect(() => {
        getAllImgByDom();
        onResize()
        window.onresize = onResize;
    }, [detail])
    const element = (<div className={`${styles.case_desc} ${className}`} style={{ height: `${descH}px` }}>
        {detail ? <div dangerouslySetInnerHTML={{ __html: detail }} className={styles.main} ref={caseDom}></div> : <Empty className={styles.empty} />}
        <PhotoSlider
            images={currentImg.map((item) => ({ src: item }))}
            visible={visible}
            onClose={() => setVisible(false)}
        />
        {/* <Viewer visible={visible} images={[{ src: currentImg[0] }]} onClose={handleClose} /> */}
    </div>);
    return element
}
export default CaseDesc