import CommonMainLayout from '@/components/CommonMainLayout';
import styles from './index.module.css';
import { Tabs, Table, Modal, Input, Button, message } from 'antd';
import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { dealToken } from '@/plugins/utils';
import { getUserInfoapi, getinvitedapi, getwithdrawlistapi, getamountlogsapi, updateaccountapi } from '@/api/rewards/index'
import Withdrawmodule from '../Getrewards/withdraw/index'
import Changemodule from '../Getrewards/ChangeModal/index';

const { Column } = Table
const { TabPane } = Tabs;


const Table01 = ({ data }) => {

    return (
        <Table dataSource={data} bordered >
            <Column title="User" dataIndex="nickName" key="nickName" />
            <Column title="Date" dataIndex="earnTime" key="time" />
            <Column title="Subscribed Plan" dataIndex="productName" key="subscribedplan" />
            <Column title="Commission" dataIndex="earnAmt" key="commission" render={(item => {
                return ('$' + item)
            })} />
            <Column title="Earned Plan (Days)" dataIndex="earnDay" key="earnedtime" render={(item) => {
                return ('+' + item)
            }} />
            <Column title="Status" dataIndex="status" key="status" render={(item) => {
                if (item == 1) {
                    return ('Available')
                } else if (item == 2) {
                    return ('Refunded')
                } else {
                    return ('Not Available')
                }
            }} >

            </Column>
        </Table>
    )
}

const Table02 = ({ data }) => {
    return (
        <Table dataSource={data} bordered >
            <Column title="Withdraw Date" dataIndex="createTime" key="id" />
            <Column title="Amount" dataIndex="amount" key="amount" render={(item) => {
                if (item) {
                    return ('$' + item)
                } else {
                    return (item)
                }
            }} />
            <Column title="Status" dataIndex="status" key="status" render={(item) => {
                if (item == 1) {
                    return ('Completed')
                } else if (item == 2) {
                    return ('Canceled')
                } else {
                    return ('Pending')
                }
            }} />
            <Column title="Note" dataIndex="reason" key="remark" />
        </Table>
    )
}

const Table03 = ({ data }) => {

    return (
        <Table dataSource={data} bordered >
            <Column title="Order Number" dataIndex="orderNo" key="id" />
            <Column title="Date" dataIndex="payTime" key="id" />
            <Column title="Subscribed Plan" dataIndex="productName" key="id" />
            <Column title="Used for Payment" dataIndex="totalPrice" key="id" render={item => ('$' + item)} />
        </Table>
    )
}






// tabs

var time = 0
const Tabsmodule = forwardRef(({ uid }, ref) => {
    const [actiontab, setactiontab] = useState('1')
    // 朋友推荐列表
    const [invitedarr, setinvitedarr] = useState([])
    // 提现记录
    const [withdrawarr, setwithdrawarr] = useState([])
    // 余额使用记录
    const [mountlogsarr, setmountlogsarr] = useState([])


    const changetabs = (key) => {
        // 限制api短时间内访问次数 防止用户过度点击单个tab太多次
        if (actiontab == key) {
            if (time) {
                return
            } else {
                time = setTimeout(() => {
                    clearTimeout(time)
                    time = 0
                }, 10000);
            }
        }
        if (key == 1) {
            getinvitedfun()
        } else if (key == 2) {
            getwithdrawlistfun()
        } else {
            getamountlogsfun()
        }
        setactiontab(key)

    }
    // 获取推荐列表
    const getinvitedfun = () => {
        getinvitedapi(uid).then(res => {
            if (res.code == 0) {
                setinvitedarr(res.rows)
            }
        })
    }

    // 获取提现记录
    const getwithdrawlistfun = () => {
        getwithdrawlistapi(uid).then(res => {
            if (res.code == 0) {
                setwithdrawarr(res.rows)
            }
        })
    }

    // 获取余额使用记录
    const getamountlogsfun = () => {
        getamountlogsapi(uid).then(res => {
            if (res.code == 0) {
                setmountlogsarr(res.rows)
            }
        })
    }

    // 用于父组件使用 提现后更新提现列表
    useImperativeHandle(ref, () => ({
        getwithdrawlistfun: getwithdrawlistfun
    }), [getwithdrawlistfun])

    useEffect(() => {
        if (uid && uid != 'false') {
            let location = window.location
            let Selectid = '1'
            if (location.search.includes('select')) {
                Selectid = location.search.split('&select=')[1]
            }
            if (Selectid == 1) {
                setactiontab('1')
                getinvitedfun()
            } else if (Selectid == 2) {
                setactiontab('2')
                getwithdrawlistfun()
            } else {
                setactiontab('3')
                getamountlogsfun()
            }
        }

    }, [0])
    return (
        <div className={styles.promotetabsbox}>
            <Tabs activeKey={actiontab} className={'promotetabs'} onTabClick={(key) => changetabs(key)}>
                <TabPane tab="Friends Invited" key="1">
                    <Table01 data={invitedarr} />
                </TabPane>
                <TabPane tab="Withdraw Record" key="2">
                    <Table02 data={withdrawarr} />
                </TabPane>
                <TabPane tab="Used Credit" key="3">
                    <Table03 data={mountlogsarr} />
                </TabPane>
            </Tabs>
        </div>
    )
})

// page

const Promotedetails = () => {

    const changeRef = useRef()

    const [userinfo, setuserinfo] = useState({})


    // 修改账户弹窗
    const [modulestate, setmodulestate] = useState(false)
    // 提现弹窗
    const [withdrawmodulestate, setwithdrawmodulestate] = useState(false)
    // 提现弹窗显示隐藏
    const showchangewithdrawmodule = () => {
        setwithdrawmodulestate(true)
    }
    const hidechangewithdrawmodule = () => {
        setwithdrawmodulestate(false)
    }
    // 修改账户弹窗显示隐藏
    const showchangemodule = () => {
        setmodulestate(true)
    }
    const hidechangemodule = () => {
        setmodulestate(false)
    }

    const getuserinfo = () => {
        getUserInfoapi(uid).then(res => {
            if (res.code == 0) {
                setuserinfo(res.data)
            }
        })
    }

    // 提现时判断是否有paypal账号
    const Withdrawfuntoispaypal = () => {
        if (userinfo.paypalAccount) {
            setwithdrawmodulestate(true)
        } else {
            setmodulestate(true)
        }
    }

    const updatetable = () => {
        changeRef.current.getwithdrawlistfun()
        getuserinfo()
    }


    const uid = dealToken();
    useEffect(() => {
        if (uid && uid != 'false') {
            getuserinfo()
        }
    }, [0])

    return (
        <CommonMainLayout>
            <div className={styles.Promotedetails_main}>
                <div className={styles.Promotedetailsprice}>
                    Credit of commission:<span className={styles.Promotedetailspricenum}>${userinfo.availableAmt}</span><span onClick={Withdrawfuntoispaypal} className={styles.withdrawbtn}>Withdraw</span>
                </div>
                <div className={styles.Promotedetailswithdrawal}>
                    <div className={styles.Promotedetailsbtntxt}>Withdraw Account:</div>
                    <span className={styles.Promotedetailsbtn1}>{userinfo.paypalAccount ? userinfo.paypalAccount : 'PayPal Only'}</span>
                    <span className={styles.Promotedetailsbtn2} onClick={showchangemodule}>Edit</span>
                </div>
                <Tabsmodule uid={uid} ref={changeRef} />
            </div>
            <Changemodule ischangeAccount={modulestate} hidechangemodule={hidechangemodule} uid={uid} getuserinfo={getuserinfo} />
            <Withdrawmodule ischangeAccount={withdrawmodulestate} hidechangemodule={hidechangewithdrawmodule} userinfo={userinfo} uid={uid} updateuser={updatetable} />
        </CommonMainLayout>

    )
}

export default Promotedetails